import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  AppBar,
  Toolbar,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import { chatService } from "../../services/chat.service";
import { Check, DoneAll } from "@mui/icons-material";

import PopupComponent from "../../modules/ImageModal";

import { accountService } from "../../services";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { uploadFile } from "../../utils/SupportChat";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles

import EmojiPicker from "emoji-picker-react";
import { extractMessageContent } from "../../utils/whatsappUtils";
import { whatsappService } from "../../services/whatsapp.service";
import { userService } from "../../services/userdetails.service";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ align: [] }],

    [{ color: [] }, { background: [] }],
    ["emoji"],
  ],
};
interface WhatsappSingleUserChat {
  setShowChat?: (showChat: boolean) => void;

  conversationId: string | null;

  token: any;
  selectedNumber: any;
  userWhoseChatIsOpen: any;
}
export default function WhatsAppSingleUserChat({
  conversationId,

  token,
  selectedNumber,
  userWhoseChatIsOpen,
}: WhatsappSingleUserChat) {
  const [conversationData, setConversationData] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [videoAttachment, setVideoAttachment] = useState<boolean>();
  const [imageModal, setImageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const textFieldRef = useRef<HTMLDivElement>(null); // Use this to refer to the TextField
  const [loggedUser, setLoggedUser] = useState<any>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [sasToken, setSasToken] = useState("");
  const [storageAccount, setStorageAccount] = useState("");
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [showEmojiPickerInMessage, setShowEmojiPickerInMessage] =
    useState(false);
  const [userTotalData, setUserTotalData] = useState<any>(null);
  const handleEmojiClick = (emoji: any) => {
    //callEmojiReactionApi(item.message.id, emoji.emoji); // Call the API function
    setShowEmojiPicker(false); // Close the emoji picker after selection
  };
  async function fetchLoggedUser() {
    const loggedUser = await accountService.userValue();
    setLoggedUser(loggedUser);
  }
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    fetchLoggedUser();
  }, [conversationData]);
  function formatMessageDate(date: any): any {
    try {
      if (!date) return "";
      if (typeof date === "string") {
        return date;
      }

      let user = accountService.userValue;

      let utcDate = new Date(date);

      const timezoneOffset = Number(user?.metadata?.timezoneOffset);

      if (!isNaN(timezoneOffset)) {
        // Adjust the date using the user's timezone offset
        utcDate = new Date(
          utcDate.getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 + // Local timezone adjustment
            timezoneOffset * 60 * 1000 // User-provided timezone adjustment
        );
      } else {
        // Returning original date if timezoneOffset is invalid
        return utcDate.toISOString();
      }
    } catch (error: any) {
      console.log(error.message);

      return "";
    }
  }
  function extractUserNameFromEmail(email: string) {
    // Split the email string at the '@' character
    const emailParts = email.split("@");

    // Check if the split resulted in an array with at least 2 elements
    if (emailParts.length >= 2) {
      // Return the part before the '@' character
      return emailParts[0];
    }

    // If the email format is incorrect, return an empty string or handle it appropriately
    return "";
  }

  const handleSend = async () => {
    if (token === "") {
      setSnackbarOpen(true);
      setSnackbarMessage("Token is needed");
    } else {
      try {
        const user = await accountService.userValue;

        setButtonLoading(true);
        try {
          if (attachments.length > 0) {
            /// Storing The File in azure storage and getting the link---------/
            let urlArray = [];

            // Initiate all uploads in parallel
            const uploadPromises = attachments.map((attachment) =>
              uploadFile(
                attachment.file,
                userWhoseChatIsOpen,
                user.role,
                sasToken,
                storageAccount
              )
            );

            // Wait for all uploads to complete
            const responses = await Promise.all(uploadPromises);

            // Add the responses to urlArray
            urlArray.push(...responses);
            setAttachments([]);
            /// storing the message on the backend------------------/
            let conversation = [
              {
                role: "support",
                content: newMessage,
                attachments: urlArray,
                supportId: user.id,
              },
            ];

            const chatResponse = await chatService.supportChatPost(
              userWhoseChatIsOpen,
              conversation,
              user.id
            );
            // let userNameFromEmail = extractUserNameFromEmail(user.email);
            let userName = user.name;
            let updatedConversation = [
              {
                role: "support",
                content: newMessage,
                attachments: urlArray,
                supportId: { id: user.id, name: userName },
                date: Date.now(),
              },
            ];

            setNewMessage("");
            setConversationData([...conversationData, ...updatedConversation]);
          } else {
            let conversation = [
              {
                role: "support",
                content: newMessage,
                supportId: user.id,
              },
            ];

            let data = {
              support_id: user.id,
              role: "support",
              support_phone_number: selectedNumber,
              user_phone_number: userWhoseChatIsOpen.contactNumber
                ? userWhoseChatIsOpen.contactNumber
                : userTotalData.contactNumber,
              user_id: userWhoseChatIsOpen._id,
              message: {
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: userWhoseChatIsOpen.contactNumber
                  ? userWhoseChatIsOpen.contactNumber
                  : userTotalData.contactNumber,
                timestamp: Math.floor(Date.now() / 1000).toString(),

                type: "text",
                text: {
                  body: newMessage,
                },
              },
              token: token,
            };

            const chatResponse = await whatsappService.sendWhatsAppMessage(
              data
            );

            if (chatResponse.success) {
              setNewMessage("");
              setSnackbarOpen(true);
              setSnackbarMessage("Message has been sent");
              setConversationData([...conversationData, chatResponse.data[0]]);
            } else {
              setSnackbarOpen(true);
              setSnackbarMessage(chatResponse.message);
            }
            // setNewMessage("");
            // setConversationData([...conversationData, ...updatedConversation]);
          }
        } catch (error) {
          alert("Unable to procceed right now");
          setNewMessage("");
        }
      } catch (error: any) {
        alert(`Unable to procceed right now ${error.message}`);
        setNewMessage("");
      }

      setButtonLoading(false);
    }
  };

  //// Function For Fetching The Message Data------------------/
  async function fetchMessageData(conversation_id: string | null) {
    /// api call code for getting the message----/
    let conversationId = "";
    setLoading(true);
    try {
      const loginUser = await accountService.userValue;
      const [chatResponse, storageNameResponse, userDetails] =
        await Promise.all([
          whatsappService.getSingleUserConversation(userWhoseChatIsOpen._id),
          accountService.getStorageName(),
          userService.getTotalData(userWhoseChatIsOpen?._id, loginUser.role),
        ]);

      if (userDetails.success) {
        setUserTotalData(userDetails.data);
      }

      setSasToken(storageNameResponse.sasToken);
      setStorageAccount(storageNameResponse.storageName);
      if (chatResponse) {
        setConversationData(chatResponse.data);
        setLoading(false);
      } else {
        setConversationData([]);
        setLoading(false);
      }
    } catch (error) {
      setConversationData([]);
      setLoading(false);
    } finally {
    }
  }

  //// Function For handeling the attachments -----------------------------/
  const handleAttachment = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files) {
      const newAttachments = Array.from(files).map((file) => ({
        file: file,
        url: URL.createObjectURL(file),
      }));

      setAttachments((currentAttachments) => {
        // Combine old and new attachments
        const combinedAttachments = [...currentAttachments, ...newAttachments];

        // Slice the array to only keep the first 4 attachments
        return combinedAttachments.slice(0, 4);
      });

      if (files.length + attachments.length > 4) {
        alert(
          "You can only select up to 4 files. Only the first 4 files have been added."
        );
      }
    } else {
      alert("Unable to select the image. Try again.");
    }
  };

  /// Function for  and opening the images ------------------------------/

  function openAttachment(attachment: any, isVideo: any) {
    if (isVideo) {
      setImageUrl(attachment);
      setImageModal(true);
      setVideoAttachment(true);
    } else {
      /// image has been clicked------------/
      setImageUrl(attachment);
      setImageModal(true);
      setVideoAttachment(false);
    }
  }

  function chunkArray(array: any, size: any) {
    return array.reduce((acc: any, val: any, i: any) => {
      let idx = Math.floor(i / size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);
      return acc;
    }, []);
  }

  // Function to check if the URL is a video
  const isVideoUrl = (url: any) => {
    const mp4Index = url.indexOf(".mp4");
    const queryIndex = url.indexOf("?");
    return mp4Index > -1 && (queryIndex === -1 || mp4Index < queryIndex);
  };

  function resetStates() {
    setConversationData([]);
    setNewMessage("");
    setVideoAttachment(undefined);
    setImageModal(false);
    setImageUrl(undefined);
  }

  useEffect(() => {
    resetStates();

    fetchMessageData(conversationId);
  }, [userWhoseChatIsOpen]);

  const handleRemoveAttachment = (indexToRemove: any) => {
    setAttachments((currentAttachments) =>
      currentAttachments.filter((_, index) => index !== indexToRemove)
    );
  };
  const onEmojiClick = (emojiData: any, event: any) => {
    // Debugging: Log the emoji data
    if (emojiData && emojiData.emoji) {
      setNewMessage((prevInput) => prevInput + emojiData.emoji);
    } else {
      console.error("No emoji found in the selected emoji data");
    }
    setShowEmojiPicker(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default to avoid form submission

      const textarea = textFieldRef.current?.querySelector("textarea");
      if (textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const text = newMessage;
        const before = text.substring(0, start); // Text before the cursor
        const after = text.substring(end); // Text after the cursor

        // Update the state with the new string containing the inserted newline
        setNewMessage(`${before}\n${after}`);

        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd = start + 1;

          // Determine the need to scroll based on cursor position within the viewport
          const lineHeight = parseInt(
            getComputedStyle(textarea).lineHeight,
            10
          );
          const linesVisible = Math.floor(textarea.clientHeight / lineHeight);
          const cursorLine = (before.match(/\n/g) || []).length + 1; // Get line number of cursor
          const totalLines = (text.match(/\n/g) || []).length + 1; // Total lines in text

          if (cursorLine > totalLines - linesVisible) {
            textarea.scrollTop = textarea.scrollHeight;
          }
        }, 0);
      }
    } else if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault(); // Prevent default to avoid inserting a new line
    }
  };

  function handleCloseSnackbar() {
    setSnackbarOpen(!snackbarOpen);
  }

  return (
    <Box sx={{ height: "90%" }}>
      {loggedUser === userWhoseChatIsOpen ? (
        <Container>
          <Typography style={{ textAlign: "center", marginTop: 40 }}>
            You can't Start a conversation with yourself
          </Typography>
        </Container>
      ) : (
        <>
          {" "}
          <AppBar position="static">
            <Toolbar>
              <Box sx={{ height: "100%" }}>
                {loading ? (
                  <Container>
                    <CircularProgress />
                  </Container>
                ) : (
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "white",
                      boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                    }}
                  >
                    <img
                      src={`https://${storageAccount}.blob.core.windows.net/user-data/${userWhoseChatIsOpen}/profile-pic?${sasToken}`}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </Box>
              <Box flexGrow={1} />
              <IconButton edge="end" color="inherit"></IconButton>

              <IconButton edge="start" color="inherit">
                <ChatIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Paper
            style={{
              height: "70%",
              width: "100%",
              overflow: "auto",
            }}
            ref={chatContainerRef}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              {loading ? (
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Container>
              ) : (
                <>
                  {conversationData &&
                    conversationData.length !== 0 &&
                    conversationData.map((item, index) => (
                      <Box
                        key={index}
                        style={{
                          alignSelf:
                            item?.role === "support"
                              ? "flex-end"
                              : "flex-start",
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 12,
                          maxWidth: "80%",
                          position: "relative",
                          marginRight: 6,
                        }}
                      >
                        <Container
                          style={{
                            backgroundColor:
                              item?.role === "support"
                                ? "rgba(93, 173, 226, 0.2)" // Light blue for support
                                : "rgba(144, 238, 144, 0.3)", // Light green for user
                            padding: 14,
                            borderRadius: 16,
                            borderBottomLeftRadius:
                              item?.role === "support" ? 16 : 0,
                            borderBottomRightRadius:
                              item?.role === "support" ? 0 : 16,
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            width: "auto",
                            maxWidth: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          // onClick={() =>
                          //   setShowEmojiPickerInMessage((prev) => !prev)
                          // }
                        >
                          {/* {showEmojiPickerInMessage && (
                            <Box
                              style={{
                                position: "absolute",
                                top: "100%", // Position below the container
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: 1000,
                                backgroundColor: "#fff",
                                border: "1px solid #ddd",
                                borderRadius: 8,
                                boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                              }}
                            >
                              <EmojiPicker onEmojiClick={handleEmojiClick} />
                            </Box>
                          )} */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            {/* Extract Message Content */}
                            {item.message && (
                              <Typography
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                  whiteSpace: "pre-wrap", // Preserve whitespace and newlines
                                  fontSize: 14,
                                  color: "#333", // Darker text for better contrast
                                }}
                              >
                                <span>
                                  {
                                    extractMessageContent(
                                      item.message,
                                      conversationData
                                    ).body
                                  }
                                </span>

                                {/* Reaction (Smiley) */}
                                {item.reaction && (
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "-20%",
                                      zIndex: 10,
                                      left:
                                        item?.role === "support" ? "unset" : 10,
                                      right:
                                        item?.role === "support" ? 10 : "unset",
                                      fontSize: 18,
                                    }}
                                  >
                                    {item.reaction}
                                  </span>
                                )}
                              </Typography>
                            )}

                            {/* Display support agent's name */}
                            {item.role === "support" && item.supportId && (
                              <Typography
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                  textAlign: "end",
                                  fontSize: 10,
                                  color: "#555",
                                }}
                              >
                                {item.supportId.name
                                  ? item.supportId.name
                                  : item.supportId.email
                                  ? extractUserNameFromEmail(
                                      item.supportId.email
                                    )
                                  : ""}
                              </Typography>
                            )}

                            {/* Display Date */}
                            {(item?.message?.timestamp || item?.createdAt) && (
                              <Typography
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                  textAlign: "end",
                                  fontSize: 10,
                                  color: "#999", // Light grey color for the date
                                  marginTop: 4,
                                }}
                              >
                                {item?.message?.timestamp
                                  ? formatMessageDate(
                                      extractMessageContent(
                                        item.message,
                                        conversationData
                                      ).timestamp
                                    )
                                  : item.createdAt}
                              </Typography>
                            )}
                          </Box>

                          {/* Double Arrow for Message Status */}
                          {item.status ? (
                            <Box
                              style={{
                                position: "absolute",
                                bottom: -4, // Position below the message box
                                right: 10, // Align to the right side
                                display: "flex",
                                alignItems: "center",
                                fontSize: 14,
                                color:
                                  item.status === "seen"
                                    ? "#0d6efd"
                                    : "#757575", // Blue for "seen", gray otherwise
                              }}
                            >
                              {item.status === "sent" && (
                                <Check style={{ fontSize: 16 }} />
                              )}
                              {item.status === "delivered" && (
                                <DoneAll style={{ fontSize: 16 }} />
                              )}
                              {item.status === "seen" && (
                                <DoneAll
                                  style={{ fontSize: 16, color: "#0d6efd" }}
                                />
                              )}
                            </Box>
                          ) : null}
                        </Container>
                      </Box>
                    ))}
                </>
              )}
            </Box>
          </Paper>
          <Paper style={{ height: "auto" }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {/* Container for attachment previews */}
              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  marginBottom: 0,
                  whiteSpace: "nowrap",
                }}
              >
                {attachments.map((attachment, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={attachment.url}
                      alt={`attachment-${index}`}
                      style={{
                        width: 50,
                        height: 50,
                        objectFit: "cover",
                        marginBottom: 5,
                      }}
                    />
                    <button onClick={() => handleRemoveAttachment(index)}>
                      Remove
                    </button>
                  </div>
                ))}
              </div>

              {/* Input field and buttons */}
              <Box
                p={2}
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="file"
                    multiple
                    accept="image/*,video/*"
                    style={{ display: "none" }}
                    id="file-input"
                    onChange={handleAttachment}
                  />
                  <label htmlFor="file-input">
                    <AttachmentIcon
                      style={{ fontSize: 30, marginRight: 5, marginTop: 4 }}
                    />
                  </label>

                  <EmojiEmotionsIcon
                    onClick={() => setShowEmojiPicker((val) => !val)}
                    style={{ fontSize: 25, cursor: "pointer" }}
                  />

                  {showEmojiPicker && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "70px",
                        zIndex: 50,
                      }}
                    >
                      <EmojiPicker onEmojiClick={onEmojiClick} />
                    </div>
                  )}
                </div>

                {/* <ReactQuill
                  value={newMessage}
                  onChange={setNewMessage}
                  modules={modules}
                  placeholder="Type your message..."
                  style={{ flexGrow: 1, marginRight: "2px", marginLeft: "4px" }}
                />

              */}
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={2}
                  maxRows={3}
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                  ref={textFieldRef} // Attach the ref here
                />
                {buttonLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 2,
                    }}
                  >
                    <CircularProgress size={25} />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleSend}
                    style={{ marginLeft: "2px" }}
                  >
                    Send
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </>
      )}
      <PopupComponent
        isPopupOpen={imageModal}
        imageUrl={imageUrl}
        setIsPopupOpen={setImageModal}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Box>
  );
}
