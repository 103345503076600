/** @format */

import {
  Grid,
  Card,
  Box,
  CircularProgress,
  Container,
  Typography,
  CardContent,
} from "@mui/material";
import { memo, useRef } from "react";
import {
  getAllMeals,
  getTargetsForWeek,
} from "../services/userdetails.service";

import React, { useEffect, useState } from "react";

import {
  calculateTotalCalorieAndProteinForWeekIndividually,
  getWeekFromDate,
  filterLastSevenDaysForSteps,
  filterLastSevenDaysWithMaxWaterValue,
  weekArrayDataOfWeights,
  getWeeklySleepData,
} from "../services/userdetails.service";
import { Skeleton, Stack } from "@mui/material";

import BasicInfoModal from "../modules/BasicInfoModal";
import ContentLoader from "react-content-loader";
import CurrentTargets from "../Components/UserDetails/CurrentTarget";
import UserDeviceInfo from "../Components/UserDetails/UserDeviceInfo";
import UserAppActivity from "../Components/UserDetails/UserAppActivity";
import UserBasicInfo from "../Components/UserDetails/UserBasicInfo";
import { userDetailsUtils } from "../utils/UserDetails";
import UserGraph from "../Components/UserDetails/UserGraph";

import { DayTargets } from "../interface/Targets";

import { AppState } from "../interface/User";

interface UserDetails {
  selectedUser: any;
  appState: AppState;
  setAppState: (data: AppState) => void;
  userDataLoading: boolean;
  setCurrentTime: any;
  currentTime: any;
  setSelectedUser: any;

  settotalUsers: any;
}
const ShimmerLoader = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      margin: 0,
      padding: 0,
      overflow: "hidden", // Ensure no extra space from children
    }}
  >
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{
        display: "block",
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden", // Prevent SVG overflow
      }}
    >
      {/* A single rectangle covering the entire card */}
      <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%" />
    </ContentLoader>
  </div>
);

const UserDetails = ({
  selectedUser,
  appState,
  setAppState,
  userDataLoading,
  setCurrentTime,
  currentTime,
  setSelectedUser,
  settotalUsers,
}: UserDetails) => {
  const [stepsWeekArray, setStepsWeekArray] = useState<any[]>([]);
  const [weightsWeekArray, setWeightsWeekArray] = useState<any[]>([]);
  const [waterWeekArray, setWaterWeekArray] = useState<any[]>([]);
  const [proteinTotals, setProteinTotals] = useState<any[]>([]);
  const [calorieTotals, setCalorieTotals] = useState<any[]>([]);
  const [sleepTotals, setSleepTotals] = useState<any[]>([]);

  const [open, setOpen] = useState(false);
  const [weekTargets, setWeekTargets] = useState<DayTargets[]>([]);

  const [dataType, setDataType] = React.useState("steps");
  const [mainDataType, setMainDataType] = React.useState("");

  const [dateRange, setDateRange] = useState({
    starting: "",
    ending: "",
  });

  const [currentDate, setCurrentDate] = React.useState(new Date());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const shimmerKeyframes = `
  @keyframes shimmer {
    0% {
      background-position: 200% 0; /* Start the gradient off-screen to the left */
    }
    100% {
      background-position: -200% 0; /* Move the gradient completely off-screen to the right */
    }
  }
`;
  function resetState() {
    setDataType(mainDataType !== "" ? mainDataType : "steps");
    setCurrentDate(new Date());
    setDateRange({
      starting: "",
      ending: "",
    });

    setCalorieTotals([]);
    setWeightsWeekArray([]);
    setSleepTotals([]);
    setProteinTotals([]);
    setStepsWeekArray([]);
    setWaterWeekArray([]);
  }

  useEffect(() => {
    resetState();
  }, [selectedUser]);

  // Function to reset state on error
  function resetDataOnFetchError() {
    setCalorieTotals([]);
    setWeightsWeekArray([]);

    setSleepTotals([]);
    setProteinTotals([]);
    setStepsWeekArray([]);
    setWaterWeekArray([]);
  }

  ///Function For getting all the user Data including protein,calorie,steps and water-----------------/
  async function getAllUserData(
    mealsData: any,
    physicalData: any,
    currentDate: any,
    selectedUser: any,
    targetLevels: any[]
  ) {
    try {
      const weekArray = await getWeekFromDate(currentDate);

      const dateRange = await userDetailsUtils.getStartAndEndDate(weekArray);

      setDateRange(dateRange);
      const stepsWeekArray = await filterLastSevenDaysForSteps(
        physicalData?.steps,

        weekArray
      );

      const targetValuesForTheWeek = getTargetsForWeek(
        dateRange.starting,
        dateRange.ending,
        targetLevels,
        selectedUser
      );

      setWeekTargets(targetValuesForTheWeek);

      const waterWeekArray = await filterLastSevenDaysWithMaxWaterValue(
        physicalData?.water,

        weekArray
      );

      const weightsWeekArray = await weekArrayDataOfWeights(
        physicalData?.weights,
        weekArray
      );

      const sleepWeekArray = await getWeeklySleepData(
        physicalData?.sleep,
        weekArray,
        selectedUser
      );

      const proteinTotals =
        await calculateTotalCalorieAndProteinForWeekIndividually(
          mealsData,
          "protein",
          weekArray
        );

      const calorieTotals =
        await calculateTotalCalorieAndProteinForWeekIndividually(
          mealsData,
          "calorie",
          weekArray
        );

      setCalorieTotals(calorieTotals);
      setProteinTotals(proteinTotals);
      setSleepTotals(sleepWeekArray);
      setWeightsWeekArray(weightsWeekArray);
      setStepsWeekArray(stepsWeekArray);
      setWaterWeekArray(waterWeekArray);

      // Calculate maxYValue based on the current dataType
      const currentMaxValue = Math.max(
        ...stepsWeekArray.map((item) => item.steps)
      );
      const calculatedMaxYValue = Math.max(
        currentMaxValue
        //  targetLevels[dataType] * 1.2
      );
    } catch (error) {
      resetDataOnFetchError();
    }
  }

  useEffect(() => {
    let date = new Date();
    getAllUserData(
      appState.meals,
      appState.physicalData,
      date,
      selectedUser,
      appState.totalTargets
    );
  }, [selectedUser]);
  /// Function For handeling the weeks when pressed the arrow----------------/
  const handlePrevWeek = () => {
    const prevWeek = new Date(currentDate);

    prevWeek.setDate(currentDate.getDate() - 7);

    setCurrentDate(prevWeek);

    getAllUserData(
      appState.meals,
      appState.physicalData,
      prevWeek,
      selectedUser,
      appState.totalTargets
    );
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(currentDate.getDate() + 7);
    setCurrentDate(nextWeek);
    getAllUserData(
      appState.meals,
      appState.physicalData,
      nextWeek,
      selectedUser,
      appState.totalTargets
    );
  };

  const handleTypeChange = (
    event: any,
    newType: React.SetStateAction<string> | null
  ) => {
    if (newType !== null) {
      setMainDataType(newType);

      // Avoid unselecting all buttons
      setDataType(newType);
    }
  };

  return (
    <Box sx={{ height: "87vh", overflowY: "auto" }}>
      <Grid container>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card
            sx={{
              height: "290px",
              overflowY: "auto",
              position: "relative", // Ensure the shimmer stays within the card bounds
            }}
          >
            {userDataLoading ? (
              <ShimmerLoader />
            ) : (
              // Content after loading
              <CardContent sx={{ padding: "16px 16px 32px 16px" }}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: "bold" }}
                  color="#000"
                  gutterBottom
                >
                  Basic Info
                </Typography>
                <UserBasicInfo
                  selectedUser={selectedUser}
                  handleOpen={handleOpen}
                  settotalUsers={settotalUsers}
                  setSelectedUser={setSelectedUser}
                />
              </CardContent>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card
            sx={{
              height: "290px",
              "@media (max-width: 1280px)": {
                height: "410px",
              },
              "@media (max-width: 500px)": {
                height: "290px",
              },
              position: "relative", // Maintain shimmer loader within bounds
            }}
          >
            {userDataLoading ? (
              <ShimmerLoader />
            ) : (
              // Content after loading
              <CardContent>
                <CurrentTargets userTargets={appState.userTargets} />
              </CardContent>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{ padding: 1.5 }}>
          <Card sx={{ height: 460 }}>
            {userDataLoading ? (
              <ShimmerLoader />
            ) : (
              <UserGraph
                dataType={dataType}
                weightData={appState.weightData}
                dateRange={dateRange}
                currentDate={currentDate}
                handlePrevWeek={handlePrevWeek}
                handleNextWeek={handleNextWeek}
                handleTypeChange={handleTypeChange}
                userTargets={appState.userTargets}
                selectedUser={selectedUser}
                targetLevels={appState.totalTargets}
                stepsWeekArray={stepsWeekArray}
                weightsWeekArray={weightsWeekArray}
                proteinTotals={proteinTotals}
                calorieTotals={calorieTotals}
                sleepTotals={sleepTotals}
                waterWeekArray={waterWeekArray}
                weekTargets={weekTargets}
                setCurrentTime={setCurrentTime}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card sx={{ overflowY: "auto", height: 230 }}>
            {userDataLoading ? (
              <ShimmerLoader />
            ) : (
              <UserAppActivity appState={appState} />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card sx={{ height: 230 }}>
            {userDataLoading ? (
              <ShimmerLoader />
            ) : (
              <UserDeviceInfo selectedUser={selectedUser} />
            )}
          </Card>
        </Grid>
      </Grid>
      {open ? (
        <BasicInfoModal
          open={open}
          onClose={handleClose}
          selectedUser={selectedUser}
        />
      ) : null}
    </Box>
  );
};

export default memo(UserDetails);
