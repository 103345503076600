import {
  Avatar,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  IconButton,
  Collapse,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import EggIcon from "@mui/icons-material/Egg";
import InventoryIcon from "@mui/icons-material/Inventory";

interface IngredientMeal {
  row: any;
  setImageUrl: any;
  setImageModal: any;
  totalCalories: any;
  totalProtein: any;
  totalQuantity: any;
}
const placeholderImage = require("../assets/placeholder.png");
export default function IngredientMeal({
  row,
  setImageModal,
  setImageUrl,
  totalQuantity,
  totalCalories,
  totalProtein,
}: IngredientMeal) {
  console.log("this is row");
  console.log(row);
  const [loading, setLoading] = React.useState(true);

  const [expanded, setExpanded] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);
  const [expandedIngredients, setExpandedIngredients] = React.useState(
    Array(row.ingredients.length).fill(false)
  );
  const ingredientRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expandedIngredients && ingredientRef.current) {
      ingredientRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [expandedIngredients]);

  const handleIngredientExpandClick = (index: number) => {
    const newExpandedIngredients = [...expandedIngredients];
    newExpandedIngredients[index] = !newExpandedIngredients[index];
    setExpandedIngredients(newExpandedIngredients);
  };

  const handleToggleClick = () => {
    setExpanded(!expanded);
    setImageError(false);
  };
  const handleError = (e: any) => {
    e.target.src = placeholderImage;
    setImageError(true);
  };

  useEffect(() => {
    setImageError(false); // Reset image error state when row.image changes
    setLoading(true); // Start loading when the image changes
  }, [row.image]);
  return (
    <div
      style={{
        marginBottom: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card style={{ width: "90%" }}>
        <CardContent style={{ display: expanded ? "none" : "block" }}>
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              {row.mealName}
            </Typography>
          </Container>
          <Divider style={{ width: "98%" }} />
          <Grid container style={{ marginTop: 5 }}>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {/* Half width */}
              <div
                style={{
                  width: "100%",
                  height: 150,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {loading && (
                  <div style={{ position: "absolute" }}>
                    <CircularProgress />
                    {/* Replace with a spinner if needed */}
                  </div>
                )}
                <img
                  onClick={() => {
                    if (imageError) return;
                    setImageUrl(row.image);
                    setImageModal(true);
                  }}
                  src={row.image}
                  onLoad={() => setLoading(false)} // Hide loader when image loads
                  onError={handleError}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    display: loading ? "none" : "block", // Hide image while loading
                  }}
                  alt="image"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {" "}
              {/* Half width */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <WhatshotIcon
                      style={{ marginRight: 5, color: "#1067bf", fontSize: 20 }}
                    />
                    <Typography variant="body2" align="center">
                      Total Calories:
                    </Typography>
                  </div>
                  <Typography variant="body2" align="center">
                    {totalCalories}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <EggIcon
                      style={{ marginRight: 5, color: "#FFD180", fontSize: 20 }}
                    />
                    <Typography variant="body2" align="center">
                      Total Protein:{" "}
                    </Typography>
                  </div>
                  <Typography variant="body2" align="center">
                    {totalProtein != null
                      ? Number.isInteger(totalProtein)
                        ? totalProtein
                        : parseFloat(totalProtein.toFixed(1))
                      : ""}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <div
            onClick={handleToggleClick}
            style={{
              cursor: "pointer",
              alignItems: "center",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Typography style={{ marginRight: "2%" }}>Show</Typography>
            <Avatar
              style={{ backgroundColor: "grey", width: "20px", height: "20px" }}
            >
              <KeyboardArrowDownIcon
                style={{ fontSize: "20px", color: "white" }}
                onClick={handleToggleClick}
              />
            </Avatar>
          </div>
        </CardContent>
        {expanded && (
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                textAlign: "center",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              {row.mealName}
            </Typography>

            <Divider style={{ width: "99%" }} />
            {row.ingredients.map((ingredient: any, index: any) => (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "80%",
                      justifyContent: "flex-start",
                      marginTop: 10,
                    }}
                  >
                    <Typography style={{ fontWeight: "700", marginLeft: 10 }}>
                      {ingredient.name}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "flex-start",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between",
                        marginLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "33%",
                        }}
                      >
                        <WhatshotIcon
                          style={{
                            marginRight: 5,
                            color: "#1067bf",
                            fontSize: 20,
                          }}
                        />
                        <Typography variant="body2" align="center">
                          {ingredient.calories} kcals
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "33%",
                        }}
                      >
                        <EggIcon
                          style={{
                            marginRight: 5,
                            color: "#FFD180",
                            fontSize: 20,
                          }}
                        />
                        <Typography variant="body2" align="center">
                          {ingredient.protein.toFixed(1)} g
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "33%",
                        }}
                      >
                        <InventoryIcon
                          style={{
                            marginRight: 5,
                            color: "green",
                            fontSize: 16,
                          }}
                        />
                        <Typography variant="body2" align="center">
                          {ingredient.quantity > 0
                            ? `${ingredient.quantity} ${ingredient.unit}`
                            : "-"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider style={{ width: "95%", marginTop: 10 }} />
                <div
                  style={{
                    display: "flex",
                    marginTop: 15,
                    marginBottom: 15,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleIngredientExpandClick(index)}
                    style={{ color: "#1067bf" }}
                  >
                    {expandedIngredients[index] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </div>
                <Collapse
                  in={expandedIngredients[index]}
                  ref={ingredientRef}
                  timeout="auto"
                  unmountOnExit
                  style={{ width: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography>Carbohydrates</Typography>
                    </div>
                    <div>
                      <Typography>
                        {" "}
                        {ingredient.carbohydrates
                          ? ingredient.carbohydrates
                          : 0}{" "}
                        g
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography>Fat</Typography>
                    </div>
                    <div>
                      <Typography>
                        {" "}
                        {ingredient.fats ? ingredient.fats : 0} g
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography>Fiber</Typography>
                    </div>
                    <div>
                      <Typography>
                        {ingredient.fiber ? ingredient.fiber : 0} g
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography>Sugar</Typography>
                    </div>
                    <div>
                      <Typography>
                        {" "}
                        {ingredient.sugar ? ingredient.sugar : 0} g
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography>Polly Unsaturated Fat</Typography>
                    </div>
                    <div>
                      <Typography>
                        {" "}
                        {ingredient.poly_unsaturated_fat
                          ? ingredient.poly_unsaturated_fat
                          : 0}{" "}
                        g
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography>Mono Unsaturated Fat</Typography>
                    </div>
                    <div>
                      <Typography>
                        {" "}
                        {ingredient.mono_unsaturated_fat
                          ? ingredient.mono_unsaturated_fat
                          : 0}{" "}
                        g
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography>Saturated Fat</Typography>
                    </div>
                    <div>
                      <Typography>
                        {" "}
                        {ingredient.saturated_fat
                          ? ingredient.saturated_fat
                          : 0}{" "}
                        g
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography>Trans Fat</Typography>
                    </div>
                    <div>
                      <Typography>
                        {" "}
                        {ingredient.trans_fat ? ingredient.trans_fat : 0} g
                      </Typography>
                    </div>
                  </div>
                  <Divider style={{ width: "95%" }} />
                </Collapse>
              </>
            ))}

            <div
              style={{
                cursor: "pointer",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "90%",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "grey",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <KeyboardArrowUpIcon
                    style={{ fontSize: "30px", color: "white" }}
                    onClick={handleToggleClick}
                  />
                </Avatar>
              </div>
            </div>
          </CardContent>
        )}
      </Card>
    </div>
  );
}
