import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { additionalService } from "../../services/additional.service";

interface StarRatingFormProps {
  onClose: () => void;
  onSave: (data: any) => void;
  initialData?: {
    uuid: string;
    campaign_id: string;
    sequence: number;
    type: string;
    input_fields: {
      title: string;
      body?: string;
      imageUrl?: string | null;
      feedbackText?: string;
      feedbackBoxPlaceholder?: string;
      ratingType: "5star" | "10star";
      feedbackType: "withFeedback" | "withoutFeedback";
      redirectionPage?: string | null;
    };
  };
  campaignId: string | null;
  type: string | null;
  sequence: any;
  editingInteractionId: any;
  onEdit: (data: any) => void;
}
interface FormDataType {
  title: string;
  body: string;
  feedbackText: string;
  feedbackBoxPlaceholder: string;
  ratingType: string;
  feedbackType: string;
  image: File | null;
  imageUrl: string | null;
  imageName: string | null;
  redirectionPage: string | null;
  uuid?: string; // Optional UUID field
}

const StarRatingForm: React.FC<StarRatingFormProps> = ({
  onClose,
  onSave,
  initialData,
  campaignId,
  type,
  sequence,
  editingInteractionId,
  onEdit,
}) => {
  // Consolidated formData state
  const [formData, setFormData] = useState<FormDataType>(() => {
    const baseFormData: FormDataType = {
      title: initialData?.input_fields?.title || "",
      body: initialData?.input_fields?.body || "",
      redirectionPage: initialData?.input_fields?.redirectionPage || null,
      feedbackText: initialData?.input_fields?.feedbackText || "",
      feedbackBoxPlaceholder:
        initialData?.input_fields?.feedbackBoxPlaceholder || "",
      ratingType: initialData?.input_fields?.ratingType || "5star",
      feedbackType:
        initialData?.input_fields?.feedbackType || "withoutFeedback",
      image: null, // Handle new image file
      imageUrl: initialData?.input_fields?.imageUrl || null, // Existing image URL
      imageName: initialData?.input_fields?.imageUrl
        ? initialData.input_fields.imageUrl.split("/").pop() || ""
        : null, // Extract image name from URL
    };

    return initialData?.uuid
      ? { ...baseFormData, uuid: initialData.uuid }
      : baseFormData;
  });

  const [loading, setLoading] = useState(false);

  // Update the image name if imageUrl is updated in initialData
  useEffect(() => {
    if (initialData?.input_fields?.imageUrl) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        imageName: initialData.input_fields.imageUrl?.split("/").pop() || "",
      }));
    }
  }, [initialData]);

  // Handle input field changes
  const handleInputChange = (
    field: string,
    value: string | "5star" | "10star" | "withFeedback" | "withoutFeedback"
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  // Handle image file changes
  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        setFormData((prevFormData) => ({
          ...prevFormData,
          image: file,
          imageName: file.name, // Set image name
        }));
      }
    },
    []
  );

  // Remove the image
  const handleRemoveImage = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      imageFile: null,
      imageName: null,
      imageUrl: null, // Clear image URL if removing image
    }));
  };

  const handleSubmit = async () => {
    if (!formData.title) {
      alert("Title is required.");
      return;
    }

    setLoading(true);
    let imageUrl = formData.imageUrl;

    try {
      if (formData.image) {
        imageUrl = await additionalService.uploadImageOnAzure(formData.image);
      }

      // Ensure feedbackText and feedbackBoxPlaceholder are included as empty strings if undefined
      const saveData = {
        campaign_id: campaignId, // Keep existing campaign_id
        sequence: sequence, // Keep existing sequence
        type: type, // Keep existing type
        input_fields: {
          title: formData.title,
          body: formData.body,
          imageUrl, // Either the new or existing image URL
          feedbackText: formData.feedbackText || "", // Ensure empty string if undefined
          feedbackBoxPlaceholder: formData.feedbackBoxPlaceholder || "", // Ensure empty string if undefined
          ratingType: formData.ratingType, // Add ratingType field
          feedbackType: formData.feedbackType, // Add feedbackType field
          redirectionPage: formData.redirectionPage,
        },
      };

      onSave(saveData); // Pass the structured data
      onClose(); // Close the form after saving
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = useCallback(async () => {
    setLoading(true);

    let imageUrl = formData.imageUrl; // Use existing image URL if no new image is uploaded

    try {
      if (formData.image) {
        // If a new image is selected, upload it
        imageUrl = await additionalService.uploadImageOnAzure(formData.image);
      }
      if ("uuid" in formData) {
        // Save the form data with updated values
        // Save the form data with updated values
        onEdit({
          index: editingInteractionId,
          campaign_id: campaignId,
          uuid: formData.uuid,
          sequence: sequence,
          type: type,
          input_fields: {
            title: formData.title,
            body: formData.body,
            imageUrl, // Either the new or existing image URL
            feedbackText: formData.feedbackText || "", // Ensure empty string if undefined
            feedbackBoxPlaceholder: formData.feedbackBoxPlaceholder || "", // Ensure empty string if undefined
            ratingType: formData.ratingType, // Add ratingType field
            feedbackType: formData.feedbackType, // Add feedbackType field
            redirectionPage: formData.redirectionPage,
          },
        });
      }
      onClose();
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  }, [
    formData,
    campaignId,
    sequence,
    type,
    onEdit,
    onClose,
    editingInteractionId,
  ]);
  return (
    <Box
      sx={{
        width: 400,
        maxHeight: "60vh",
        overflowY: "auto",
        mx: "auto",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "background.paper",
        borderRadius: 2,
        boxShadow: 3,
        padding: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Star Rating Form
      </Typography>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.title}
        onChange={(e) => handleInputChange("title", e.target.value)}
      />
      <TextField
        label="Body"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.body}
        onChange={(e) => handleInputChange("body", e.target.value)}
      />
      <Box sx={{ mb: 2, textAlign: "center" }}>
        <Button variant="outlined" component="label" color="primary">
          Upload Image (Optional)
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleImageChange}
          />
        </Button>
        {formData.imageName && (
          <Box sx={{ mt: 2, position: "relative", textAlign: "left" }}>
            <Typography variant="body2">{formData.imageName}</Typography>
            <IconButton
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={handleRemoveImage}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="feedbackType-label">Feedback Type</InputLabel>
        <Select
          labelId="feedbackType-label"
          value={formData.feedbackType}
          onChange={(e) =>
            handleInputChange(
              "feedbackType",
              e.target.value as "withFeedback" | "withoutFeedback"
            )
          }
          label="Feedback Type"
        >
          <MenuItem value="withoutFeedback">Without Feedback</MenuItem>
          <MenuItem value="withFeedback">With Feedback</MenuItem>
        </Select>
      </FormControl>
      {formData.feedbackType === "withFeedback" && (
        <>
          <TextField
            label="Feedback Text"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={formData.feedbackText}
            onChange={(e) => handleInputChange("feedbackText", e.target.value)}
          />
          <TextField
            label="Feedback Box Placeholder"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={formData.feedbackBoxPlaceholder}
            onChange={(e) =>
              handleInputChange("feedbackBoxPlaceholder", e.target.value)
            }
          />
        </>
      )}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="ratingType-label">Rating Type</InputLabel>
        <Select
          labelId="ratingType-label"
          value={formData.ratingType}
          onChange={(e) =>
            handleInputChange(
              "ratingType",
              e.target.value as "5star" | "10star"
            )
          }
          label="Rating Type"
        >
          <MenuItem value="5star">5 Star</MenuItem>
          <MenuItem value="10star">10 Star</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Redirection Page</InputLabel>
        <Select
          value={formData.redirectionPage || ""}
          onChange={(e) => handleInputChange("redirectionPage", e.target.value)}
          variant="outlined"
          label="Redirection Page"
          displayEmpty
        >
          <MenuItem value="diet plan">Diet Plan</MenuItem>
          <MenuItem value="history">History</MenuItem>
          <MenuItem value="workouts">Workouts</MenuItem>
          <MenuItem value="nextinteraction">Next Interactions</MenuItem>
        </Select>
      </FormControl>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {initialData ? (
            <Button variant="contained" color="primary" onClick={handleEdit}>
              Update
              {/* Change button text based on mode */}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Add
              {/* Change button text based on mode */}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default StarRatingForm;
