import { config } from "../shared/constants/config";

import { fetchWrapper } from "../helpers";

const baseUrl = `${config.whatsAppBackendApiUrl}/api`;
export const whatsappService = {
  getSingleUserConversation,
  sendWhatsAppMessage,
  getWhatsAppMessage,
  sendTemplateMessage,
  getWhatsAppTemplates,
  createTemplate,
  getTemplateAudiences,
};

//// Function for sending the whats app message -----------------------------------------------/
async function sendWhatsAppMessage(data: any) {
  return fetchWrapper.post(`${baseUrl}/send-message`, {
    ...data,
  });
}
//// Function for getting the audiences of the template -----------------------------------------------/
async function getTemplateAudiences(templateName: any) {
  return fetchWrapper.get(
    `${baseUrl}/get-template-audiences?templateName=${templateName}`
  );
}
//// Function for getting the whats app message -----------------------------------------------/
async function getWhatsAppMessage(conversationIds: string[]) {
  const queryParams = conversationIds
    .map((id) => encodeURIComponent(id))
    .join(",");
  return fetchWrapper.get(
    `${baseUrl}/get-whatsapp-chats?conversationIds=${queryParams}`
  );
}

//// Function for getting the whats app templates -----------------------------------------------/
async function getWhatsAppTemplates() {
  return fetchWrapper.get(`${baseUrl}/fetch-templates`);
}
//// Function for getting a single user all message data -----------------------------------------------/
async function getSingleUserConversation(userId: string) {
  return fetchWrapper.get(`${baseUrl}/get-user-conversation?userId=${userId}`);
}

//// Function for sending the template message  -----------------------------------------------/
async function sendTemplateMessage(data: any) {
  return fetchWrapper.post(`${baseUrl}/send-template`, {
    ...data,
  });
}
//// Funciton for creating the templates --------------------------------------------------/
async function createTemplate(data: any) {
  return fetchWrapper.post(`${baseUrl}/create-template`, {
    ...data,
  });
}
