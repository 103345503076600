import React, { useState } from "react";
import {
  Button,
  Typography,
  TextField,
  Box,
  Modal,
  CircularProgress,
  Container,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import {
  CurrentTemplate,
  TemplateManagerProps,
  TemplatesInterface,
} from "../../interface/WhatsAppPageInterface";
import { whatsappService } from "../../services/whatsapp.service";

const TemplateManager = ({
  handleSend,
  loading,
  templates,
  templateDetails,
  setTemplateDetails,
  setSelectedUserIds,
  setTemplates,
}: TemplateManagerProps) => {
  const [open, setOpen] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  function sendFormData(templateDetails: CurrentTemplate) {
    handleSend({
      templateName: templateDetails.templateName,
      templateLanguage: templateDetails.templateLanguage,
    });
  }
  // Handler to update specific fields
  const handleInputChange = (key: string, value: string) => {
    setTemplateDetails((prev: CurrentTemplate) => ({
      ...prev,
      [key]: value, // Dynamically update the key
    }));
  };
  const addingTemplate = () => {
    setTemplates((prev: any[]) => [
      {
        templateName: templateDetails.templateName,
        language: templateDetails.templateLanguage,
      },
    ]);
    setOpen(false);
  };

  async function getAudiences(template: TemplatesInterface, index: any) {
    try {
      console.log("this is template");
      console.log(template);

      if (selectedTemplate === template.templateName) {
        console.log("went matching here");

        // If the same template is selected, clear it out
        setSelectedTemplate(null);
        setTemplateDetails({ templateName: "", templateLanguage: "" });
        setSelectedUserIds([]);
        return;
      }

      setLoadingIndex(index);
      setSelectedTemplate(template.templateName);
      setTemplateDetails({
        templateName: template.templateName,
        templateLanguage: template.language,
      });

      const response = await whatsappService.getTemplateAudiences(
        template.templateName
      );
      if (response.success) {
        console.log(response);
        setSnackbarMessage(response.message);
        setSelectedUserIds(response.data);
      } else {
        setSnackbarMessage(response.message);
      }
      setSnackbarOpen(true);
    } catch (error: any) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    } finally {
      setLoadingIndex(null);
    }
  }

  return (
    <Box sx={{ p: 2 }}>
      {/* Add Template Button */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add Template
        </Button>
        {loading ? (
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CircularProgress />
          </Container>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => sendFormData(templateDetails)}
            disabled={
              !templateDetails.templateName || !templateDetails.templateLanguage
            }
          >
            Send
          </Button>
        )}
      </Box>

      {/* Templates List */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Templates
        </Typography>
        {templates.length === 0 ? (
          <Typography>No templates available.</Typography>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: 2,
            }}
          >
            {templates.map((template, index) => (
              <>
                {loadingIndex === index ? (
                  <Container
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Container>
                ) : (
                  <Card
                    key={index}
                    sx={{
                      borderRadius: "12px",
                      boxShadow:
                        selectedTemplate === template.templateName ? 6 : 3, // Increase shadow when selected
                      cursor: "pointer",
                      border:
                        selectedTemplate === template.templateName
                          ? "2px solid #1976D2"
                          : "none", // Highlight border
                      backgroundColor:
                        selectedTemplate === template.templateName
                          ? "#E3F2FD"
                          : "white", // Light blue background when selected
                    }}
                    onClick={() => {
                      getAudiences(template, index);
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {template.templateName}
                      </Typography>

                      <Typography variant="body2" color="textSecondary">
                        Language: {template.language}
                      </Typography>

                      <Typography variant="body2" color="textSecondary">
                        Created By: {template.createdByName}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </>
            ))}
          </Box>
        )}
      </Box>

      {/* Modal */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h6">Add New Template</Typography>
          <TextField
            label="Template Name"
            value={templateDetails.templateName}
            onChange={(e) => handleInputChange("templateName", e.target.value)}
            fullWidth
          />
          <TextField
            label="Template Language"
            value={templateDetails.templateLanguage}
            onChange={(e) =>
              handleInputChange("templateLanguage", e.target.value)
            }
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              addingTemplate();
            }}
          >
            Add
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default TemplateManager;
