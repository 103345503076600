export const extractMessageContent = (message: any, conversation: any[]) => {
  try {
    let contextLine = "";
    console.log(message);

    console.log(conversation);

    // Check if message has a context (i.e., it's a reply)
    if (message.context?.id) {
      const contextMessage = conversation.find(
        (msg) => msg.message.id === message.context.id
      );
      console.log("context");
      console.log(contextMessage);

      if (contextMessage) {
        let contextBody = "";

        // Handle different types of context messages
        if (contextMessage.message.type === "text") {
          contextBody =
            contextMessage.message.text?.body || "Text message unavailable";
        } else if (contextMessage.message.type === "template") {
          contextBody = `Template Name: ${
            contextMessage.message.template?.name || "Unknown template"
          }`;
        } else {
          contextBody = `Type: ${contextMessage.message.type}`;
        }

        contextLine = `Context: ${contextBody}`;
      }
    }

    let body = "";
    if (message.type === "text") {
      body = message?.text?.body ? message.text.body : message.text;
    } else if (message.type === "template") {
      body = `Template Name: ${message.template?.name || "Unknown template"}`;
    } else if (message.type === "button") {
      // this is a template response
      console.log("message here");
      console.log(message);

      body = ` ${message?.button?.text ? message.button.text : message.text}`;
    } else {
      body = `Type: ${message.type}`;
    }

    const timestamp = new Date(message.timestamp * 1000); // Convert timestamp to date

    return {
      body: contextLine ? `${contextLine}\n\n${body}` : body, // Add context at the top
      timestamp,
    };
  } catch (error) {
    console.error("Error extracting message content:", error);
    return {
      body: "Failed to extract message content.",
      timestamp: new Date(),
    };
  }
};
