import { memo, useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Modal,
  Box,
  Typography,
  Button,
  Stack,
  IconButton,
  Divider,
  FormControl,
  FormControlLabel,
  Checkbox,
  Collapse,
  FormGroup,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  Input,
  Tooltip,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Container,
  CircularProgress,
  Card,
  CardContent,
  Chip,
  Paper,
} from "@mui/material";
import { accountService } from "../services";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React from "react";
import {
  FilterInterface,
  FilterSettings,
  SortingModalInterface,
} from "../interface/FilterInterface";
import {
  checkBoxFilterObject,
  FilterInitialState,
  initialFilterSettings,
  isFilterName,
} from "../utils/FilterUtis";
import * as Papa from "papaparse";
import { userService } from "../services/userdetails.service";

const CustomInputStarDate = React.forwardRef(
  ({ value, onClick }: any, ref: any) => (
    <TextField
      label="Date"
      value={value}
      onClick={onClick}
      ref={ref}
      sx={{ width: "100%", marginRight: 3 }}
    />
  )
);
const CustomInputDateRangeStartDate = React.forwardRef(
  ({ value, onClick }: any, ref: any) => (
    <TextField
      label="Start Date"
      value={value}
      onClick={onClick}
      ref={ref}
      sx={{ width: "100%", marginRight: 3 }}
    />
  )
);
const CustomInputDateRangeEndDate = React.forwardRef(
  ({ value, onClick }: any, ref: any) => (
    <TextField
      label="End Date"
      value={value}
      onClick={onClick}
      ref={ref}
      sx={{ width: "100%", marginRight: 3 }}
    />
  )
);
// Custom input component for the DatePicker
const CustomInputSignUpDate = React.forwardRef((props, ref: any) => {
  return (
    <TextField
      ref={ref}
      {...props}
      label="Sign Up Date"
      variant="outlined"
      fullWidth
    />
  );
});

//// Main Function for the Sorting Modal -------------------------------------------------------------------/
function SortingModal({
  modalOpen,
  setModalOpen,
  totalUsers,
  setTotalUsers,
  setNumberOfCalls,
  listInnerRef,
  setTotalNumberOfUsers,
  setUserLoading,
  resetCheckBoxFunctionaltiy,
  setHasMoreUsers,
  filters,
  setFilters,
  fetchUsers,
  filterSettings,
  setFilterSettings,
  searchBarActive,
  searchText,
}: SortingModalInterface) {
  const [expanded, setExpanded] = useState(false);
  const [heardFromrmExpand, setheardFromrmExpand] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [countryLoading, setCountryLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeInfo, setActiveInfo] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  useEffect(() => {
    async function fetchCountries() {
      try {
        setCountryLoading(true);
        const loggedUserr = await accountService.userValue;

        /// Service function call for getting the country names-/
        const countryApiResponse = await accountService.getCountries();

        if (countryApiResponse.success === true) {
          setCountries([...countryApiResponse.countryArray]);
        } else {
          setCountries([]);
        }
      } catch (error) {
        setCountries([]);
      } finally {
        setCountryLoading(true);
      }
    }

    fetchCountries();
  }, []);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };
  const handleExpansationForheardFromrm = () => {
    setheardFromrmExpand(!heardFromrmExpand);
  };

  //const [allUsers, setAllUsers] = useState([]); // This should hold the original full list of users
  const [iconActive, setIconActive] = useState("");

  const [csvFieldName, setCsvFieldName] = useState("id"); // State to hold the field name

  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);

  const [isDatePickerDisabled, setDatePickerDisabled] = useState(false);
  const [isToggleButtonGroupDisabled, setToggleButtonGroupDisabled] =
    useState(false);
  const [isDateRangeDisabled, setDateRangeDisabled] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Function for updating the filter settings
  const updateFilterSettings = (key: any, value: boolean | undefined) => {
    setFilterSettings((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateFilterState = (key: any, value: any) => {
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleToggleButtonChange = (
    event: any,
    newValue: any,
    filterName: string
  ) => {
    if (!isFilterName(filterName)) {
      console.error(`Invalid filter name: ${filterName}`);
      return;
    }

    const filterKeys = checkBoxFilterObject[filterName];

    if (newValue === filterName) {
      // If the toggle is set to the selected filter, update its state and settings
      if (!filterSettings[filterKeys.checkBox]) {
        updateFilterSettings(filterKeys.checkBox, true);
      }
      updateFilterState(filterKeys.filter, true);
    } else if (newValue === null) {
      // If the toggle button is deselected, set the filter state to false
      updateFilterState(filterKeys.filter, false);
    }
  };

  const handleToggleButtonChangeForHeardFrom = (
    event: any,
    newValue: any,
    filterName: string
  ) => {
    if (!isFilterName(filterName)) {
      console.error(`Invalid filter name: ${filterName}`);
      return;
    }

    const filterKeys = checkBoxFilterObject[filterName];

    const filterCombinations: { [key: string]: string[] } = {
      heardFamily: [
        "heardSocialMedia",
        "heardCoWorker",
        "heardFriend",
        "heardStoreSearch",
        "heardOther",
      ],
      heardSocialMedia: [
        "heardFamily",
        "heardCoWorker",
        "heardFriend",
        "heardStoreSearch",
        "heardOther",
      ],
      heardCoWorker: [
        "heardFamily",
        "heardSocialMedia",
        "heardFriend",
        "heardStoreSearch",
        "heardOther",
      ],
      heardFriend: [
        "heardFamily",
        "heardSocialMedia",
        "heardCoWorker",
        "heardStoreSearch",
        "heardOther",
      ],
      heardStoreSearch: [
        "heardFamily",
        "heardSocialMedia",
        "heardCoWorker",
        "heardFriend",
        "heardOther",
      ],
      heardOther: [
        "heardFamily",
        "heardSocialMedia",
        "heardCoWorker",
        "heardFriend",
        "heardStoreSearch",
      ],
    };

    if (newValue === filterName) {
      // If the toggle is set to the selected filter, update its state and settings
      if (!filterSettings[filterKeys.checkBox]) {
        updateFilterSettings(filterKeys.checkBox, true);
      }
      updateFilterState(filterKeys.filter, true);

      // // Unmark the remaining filter checkboxes dynamically
      // if (filterCombinations[filterName]) {
      //   filterCombinations[filterName].forEach((combination) => {
      //     updateFilterState(`${combination}Filter`, undefined);
      //     updateFilterSettings(`${combination}CheckBox`, false);
      //   });
      // }
    } else if (newValue === null) {
      // If the toggle button is deselected, set the filter state to false
      updateFilterState(filterKeys.filter, false);
    }
  };
  // Handler for checkbox change
  const handleCheckbox = (
    event: any,
    filterName: keyof FilterInterface,
    checkBoxKey: keyof FilterSettings
  ) => {
    const checked = event.target.checked;

    // Update only the current checkbox and filter state
    updateFilterState(filterName, checked ? true : undefined);
    updateFilterSettings(checkBoxKey, checked);
  };

  const handleSort = (order: any) => {
    const sortedUsers = [...totalUsers].sort((a, b) => {
      const dateA = new Date(a.lastActive).getTime();
      const dateB = new Date(b.lastActive).getTime();

      // For ascending order: earliest lastActive first, latest lastActive last

      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
    setIconActive(order);
    setTotalUsers(sortedUsers);
  };
  const handleClearDate = () => {
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      activity: { activityType: "", date: null },
      dateRange: { startDate: null, endDate: null },
    }));
    setToggleButtonGroupDisabled(false);
    setDateRangeDisabled(false);
  };

  const handleDateChange = (date: Date | null) => {
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      activity: { ...prevState.activity, date },
    }));
    if (date) {
      setToggleButtonGroupDisabled(true);
      setDateRangeDisabled(true);
    } else {
      setToggleButtonGroupDisabled(false);
      setDateRangeDisabled(false);
    }
  };

  const handleDateRangeChange = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    const adjustedStartDate = startDate
      ? new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
      : null;
    const adjustedEndDate = endDate
      ? new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
      : null;

    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      dateRange: { startDate: adjustedStartDate, endDate: adjustedEndDate },
    }));
    if (adjustedStartDate || adjustedEndDate) {
      setDatePickerDisabled(true);
      setToggleButtonGroupDisabled(true);
    } else {
      setDatePickerDisabled(false);
      setToggleButtonGroupDisabled(false);
    }
  };

  const handleActivityTypeChange = (event: any) => {
    const value = event.target.value;
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      activity: { ...prevState.activity, activityType: value },
    }));
    if (value) {
      setToggleButtonGroupDisabled(true);
      setDateRangeDisabled(true);
    } else {
      setToggleButtonGroupDisabled(false);
      setDateRangeDisabled(false);
    }
  };

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string | null
  ) => {
    setFilters((prevState: FilterInterface) => ({
      ...prevState,
      activeType: newType,
      activity: { ...prevState.activity, date: null, activityType: "" },
    }));
    if (newType) {
      setDatePickerDisabled(true);
    } else {
      setDatePickerDisabled(false);
    }
  };

  // Function to construct API call parameters based on active filters and make the call
  // Function to construct API call parameters based on active filters and make the call
  const fetchFilteredUsers = async (filters: FilterInterface) => {
    // Construct the parameters based on active

    setUserLoading(true);

    const user = await accountService.userValue;
    let numberofCalls = 1;

    const params = {
      numberofCalls: numberofCalls,
      userGroupNumber: 20,
      deviceType: filters.deviceType, // Add device type if active
      onboarding: filters.onboardingFilter, // Add onboarding status if active
      subscription: filters.subscriptionFilter, // Add subscription status if active
      trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
      countries: filters.checkBoxCountries, // Include selected countries in the request
      notification: filters.notificationFilter,
      maxVersion: filters.maxVersion,
      minVersion: filters.minVersion,
      genderType: filters.genderType,
      goalType: filters.goalType,
      activeType: filters.activeType,
      activity: filters.activity,
      dateRange: filters.dateRange,
      contactNumber: filters.contactNumberFilter,
      notes: filters.notesFilter,
      uncheckedCountries: filters.uncheckCountries,
      messageSeen: filters.messageSeenFilter,
      countryCode: filters.countryCode,
      csvFile: filters.csvFileFilter,
      distinctIds: filters.distinctIds,
      openSupportMessage: filters.openSupportMessage,
      minimumAge: filters.minimumAge,
      maximumAge: filters.maximumAge,
      maximumBmi: filters.maximumBmi,
      minimumBmi: filters.minimumBmi,
      freePremium: filters.freePremiumFilter,
      freeTrial: filters.freeTrialFilter,
      paidFilter: filters.paidFilter,
      signUpFilter: filters.signUpFilter,
      subscriptionCanceledFilter: filters.subscriptionCanceledFilter,
      initialQuotaFilter: filters.initialQuotaFilter,
      hasGymMembershipFilter: filters.hasGymMembershipFilter,
      freePremiumOverFilter: filters.freePremiumOverFilter,
      weighingScaleFilter: filters.weighingScaleFilter,
      heardFromFilter: {
        coworker: filters.heardCoWorkerFilter,
        socialMedia: filters.heardSocialMediaFilter,
        family: filters.heardFamilyFilter,
        friend: filters.heardFriendFilter,
        storeSearch: filters.heardStoreSearchFilter,
        other: filters.heardOtherFilter,
      },
      uncheckedUserMessagesFilter: filters.uncheckedUserMessagesFilter,
    };

    if (listInnerRef.current) {
      listInnerRef.current.scrollTop = 0;
    }
    try {
      const res = await accountService.getAllUsers(params);

      setHasMoreUsers(true);
      let { users, userCount } = res;

      if (users.length > 0) {
        if (searchBarActive) {
          // Merge and filter unique users when search bar is active
          // const allUsers = [...totalUsers, ...users];

          // Find common users
          const commonUsers = totalUsers.filter((totalUser: any) =>
            users.some((newUser: any) => newUser._id === totalUser._id)
          );

          // Remove common users from the incoming users
          const filteredUsers = users.filter(
            (newUser: any) =>
              !totalUsers.some(
                (totalUser: any) => totalUser._id === newUser._id
              )
          );
          if (commonUsers.length === 0) {
            /// no common users so final users will searchbar user plus the filtered users
            // Combine common users and filtered users
            const uniqueUsers = [...totalUsers, ...filteredUsers];
            setTotalUsers(uniqueUsers);
            setTotalNumberOfUsers(userCount + totalUsers.length);
          } else {
            /// common users exists so final user will be common user plus the filtered users
            const uniqueUsers = [...commonUsers, ...filteredUsers];
            setTotalUsers(uniqueUsers);
            setTotalNumberOfUsers(userCount + uniqueUsers.length);
          }
        } else {
          // Replace totalUsers with the incoming users when search bar is not active
          setTotalUsers(users);
          setTotalNumberOfUsers(userCount);
        }

        // Check if more users are available
        if (userCount < 20) {
          setHasMoreUsers(false);
        }
      } else {
        // Reset values if no users are coming
        setTotalUsers([]);
        setTotalNumberOfUsers(0);
        setHasMoreUsers(false);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setTotalUsers([]);

      // Handle error state appropriately
    }

    setNumberOfCalls(1);
    setUserLoading(false);
    // resetCheckBoxFunctionaltiy();
  };

  const handleCountryChange = (
    country: string,
    actionType: "checkbox" | "cross"
  ) => {
    setFilters((prevFilters: any) => {
      const { checkBoxCountries, uncheckCountries } = prevFilters;
      const isInCheckBox = checkBoxCountries.includes(country);
      const isInUncheck = uncheckCountries.includes(country);

      if (actionType === "checkbox") {
        if (isInUncheck) {
          alert("Please unselect the country first.");
          return prevFilters;
        }

        if (!isInCheckBox) {
          // Add to checkbox countries if not already present
          const updatedCheckBox = [...checkBoxCountries, country];
          const updatedUncheck = uncheckCountries.filter(
            (c: any) => c !== country
          );
          return {
            ...prevFilters,
            checkBoxCountries: updatedCheckBox,
            uncheckCountries: updatedUncheck,
          };
        } else {
          // Remove from checkbox countries if already present
          const updatedCheckBox = checkBoxCountries.filter(
            (c: any) => c !== country
          );
          return { ...prevFilters, checkBoxCountries: updatedCheckBox };
        }
      } else if (actionType === "cross") {
        if (isInCheckBox) {
          alert("Please unselect the country first.");
          return prevFilters;
        }

        if (!isInUncheck) {
          // Add to uncheck countries if not already present
          const updatedUncheck = [...uncheckCountries, country];
          const updatedCheckBox = checkBoxCountries.filter(
            (c: any) => c !== country
          );
          return {
            ...prevFilters,
            checkBoxCountries: updatedCheckBox,
            uncheckCountries: updatedUncheck,
          };
        } else {
          // Remove from uncheck countries if already present
          const updatedUncheck = uncheckCountries.filter(
            (c: any) => c !== country
          );
          return { ...prevFilters, uncheckCountries: updatedUncheck };
        }
      }

      return prevFilters;
    });
  };

  function reseting() {
    //  setHasMoreUsers(true);
    setUserLoading(true);
    handleCloseModal();
    setFilterSettings(initialFilterSettings);
    setNumberOfCalls(1);
    setFilters(FilterInitialState);
    fetchUsers();
  }

  function applyFilter() {
    fetchFilteredUsers(filters);
    handleCloseModal();
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file = fileInput.files?.[0];

    if (file) {
      setFileName(file.name); // Store file name in state

      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const distinctIdsArray = results.data
            .map((row: any) => row[csvFieldName as keyof typeof row])
            .filter(Boolean);

          setFilters((prevFilters: any) => ({
            ...prevFilters,
            distinctIds: distinctIdsArray,
          }));

          // Clear file input after processing
          fileInput.value = "";
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
        },
      });
    }
  };

  // Custom handler to toggle both checkboxes when the top-level checkbox is clicked
  const handleTopLevelCheckbox = (event: any) => {
    const isChecked = event.target.checked;

    // Directly update all relevant filters and checkboxes when the top-level checkbox is toggled
    updateFilterState("subscriptionFilter", isChecked ? true : undefined);
    updateFilterSettings("subscriptionCheckBox", isChecked);

    updateFilterState("freePremiumFilter", isChecked ? true : undefined);
    updateFilterSettings("freePremiumCheckBox", isChecked);

    updateFilterState("freeTrialFilter", isChecked ? true : undefined);
    updateFilterSettings("freeTrialCheckBox", isChecked);

    updateFilterState("paidFilter", isChecked ? true : undefined);
    updateFilterSettings("paidCheckBox", isChecked);
  };

  const handleHeardAboutUsCheckbox = (event: any) => {
    const isChecked = event.target.checked;

    // Update all "heard about us" related filters and checkboxes
    updateFilterState("heardFamilyFilter", isChecked ? true : undefined);
    updateFilterSettings("heardFamilyCheckBox", isChecked);

    updateFilterState("heardSocialMediaFilter", isChecked ? true : undefined);
    updateFilterSettings("heardSocialMediaCheckBox", isChecked);
    updateFilterState("heardFriendFilter", isChecked ? true : undefined);
    updateFilterSettings("heardFriendCheckBox", isChecked);

    updateFilterState("heardCoWorkerFilter", isChecked ? true : undefined);
    updateFilterSettings("heardCoWorkerCheckBox", isChecked);

    updateFilterState("heardStoreSearchFilter", isChecked ? true : undefined);
    updateFilterSettings("heardStoreSearchCheckBox", isChecked);

    updateFilterState("heardOtherFilter", isChecked ? true : undefined);
    updateFilterSettings("heardOtherCheckBox", isChecked);
  };

  const handleCheckboxForHeard = (
    event: any,
    filterName: keyof FilterInterface,
    checkBoxKey: keyof FilterSettings
  ) => {
    const checked = event.target.checked;

    if (!checked) {
      // Deselect the current filter and checkbox
      updateFilterState(filterName, undefined);
      updateFilterSettings(checkBoxKey, false);
    } else {
      // Update the current checkbox to true
      updateFilterState(filterName, checked);
      updateFilterSettings(checkBoxKey, checked);

      // Removed the unchecking of related filters and checkboxes
    }
  };

  /// Filters static content -----------------------------------/

  const filtersStatic = [
    {
      key: "subscriptionCanceled",
      label: "Subscription Canceled",
      infoText:
        "Check the box to show users with canceled subscriptions. Turn off the toggle to show users without canceled subscriptions.",
    },
    {
      key: "activeUsers",
      label: "Active Users",
      infoText:
        "Check the box to filter active users only. Use the toggle to include or exclude inactive users.",
    },
  ];
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "scroll",
        }}
      >
        <Typography
          id="modal-title"
          style={{ fontWeight: "800", marginBottom: 3 }}
        >
          Sort Options
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginBottom: 2,
            color: "text.secondary",
            textAlign: "center",
          }}
        >
          Sort the users based on their last activity
        </Typography>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: 1,
              borderColor: "divider",
              borderRadius: 1,
              padding: 1,
              marginY: 1,
              width: "92%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  textAlign: "center",
                }}
              >
                Sort by Oldest Active Users
              </Typography>
              <IconButton
                color={iconActive === "asc" ? "secondary" : "primary"}
                onClick={() => handleSort("asc")}
              >
                <ArrowUpwardIcon />
              </IconButton>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <Typography sx={{ mx: 1 }}>Last Activity</Typography>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                color={iconActive === "desc" ? "secondary" : "primary"}
                onClick={() => handleSort("desc")}
              >
                <ArrowDownwardIcon />
              </IconButton>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  textAlign: "center",
                }}
              >
                Sort by Latest Active Users
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                width: "98%",
                padding: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                marginBottom: 3,
              }}
            >
              {/* Title */}
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginBottom: 2,
                  fontWeight: "bold",
                }}
              >
                Activity Filter
              </Typography>
              {/* Description placed on top */}
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  marginBottom: 2,
                  color: "text.secondary",
                }}
              >
                Select a date and choose either "Active" or "Inactive" to filter
                users based on their activity on that specific day.
              </Typography>

              {/* Date and Activity Type Filter */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <IconButton
                  onClick={handleClearDate}
                  disabled={isDatePickerDisabled}
                  aria-label="clear date"
                >
                  <ClearIcon />
                </IconButton>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {isDatePickerDisabled ? (
                    <TextField
                      label=" Date"
                      value=""
                      disabled
                      sx={{ width: "100%", marginRight: 3 }}
                    />
                  ) : (
                    <DatePicker
                      selected={filters.activity.date}
                      onChange={handleDateChange}
                      customInput={<CustomInputStarDate />}
                    />
                  )}
                </LocalizationProvider>

                <FormControl sx={{ minWidth: "40%" }}>
                  <InputLabel id="activity-type-label">
                    Activity Type
                  </InputLabel>
                  <Select
                    labelId="activity-type-label"
                    id="activity-type"
                    value={filters.activity.activityType}
                    onChange={handleActivityTypeChange}
                    label="Activity Type"
                    disabled={isDatePickerDisabled}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Paper>

            <Paper
              sx={{
                width: "99%",
                padding: 1,
                boxShadow: 3,
                borderRadius: "8px",
                marginBottom: 3,
              }}
            >
              {/* Title */}
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginBottom: 2,
                  fontWeight: "bold",
                }}
              >
                Filter by Date Range
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                sx={{ textAlign: "center", marginBottom: 3 }}
              >
                Select a time range to filter the data. Choose between the last
                7 days or the last 30 days.
              </Typography>

              {/* Toggle Buttons for 7 days and 30 days */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={filters.activeType}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Filter by type"
                  sx={{ width: "100%" }}
                  disabled={isToggleButtonGroupDisabled}
                >
                  <ToggleButton value="7" sx={{ width: "50%" }}>
                    Last 7 Days
                  </ToggleButton>
                  <ToggleButton value="30" sx={{ width: "50%" }}>
                    Last 30 Days
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Paper>
            <Box sx={{ width: "100%", mb: 3 }}>
              {/* Paper Wrapper for the Title, Description, and Filter */}
              <Paper
                sx={{
                  padding: 1,
                  boxShadow: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                {/* Date Range Title and Description */}
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", mb: 2, fontWeight: "bold" }}
                >
                  Date Range
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ textAlign: "center", mb: 2, color: "text.secondary" }}
                >
                  Select a start and end date to filter users who were active
                  during this range.
                </Typography>

                {/* Date Range Filter Box */}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <IconButton
                    onClick={() => handleDateRangeChange(null, null)}
                    disabled={isDateRangeDisabled}
                    aria-label="clear date range"
                  >
                    <ClearIcon />
                  </IconButton>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      selected={filters.dateRange.startDate}
                      onChange={(date: Date | null) =>
                        handleDateRangeChange(date, filters.dateRange.endDate)
                      }
                      customInput={<CustomInputDateRangeStartDate />}
                      disabled={isDateRangeDisabled}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      selected={filters.dateRange.endDate}
                      onChange={(date: Date | null) =>
                        handleDateRangeChange(filters.dateRange.startDate, date)
                      }
                      customInput={<CustomInputDateRangeEndDate />}
                      disabled={isDateRangeDisabled}
                    />
                  </LocalizationProvider>
                </Box>
              </Paper>
            </Box>
          </Box>
          <Paper
            sx={{
              width: "99%",
              padding: 1,
              boxShadow: 3,
              borderRadius: "8px",
              marginBottom: 3,
            }}
          >
            {/* Title */}
            <Typography
              variant="h6"
              sx={{ textAlign: "center", marginBottom: 2, fontWeight: "BOLD" }}
            >
              Filter Users by Sign-Up Date
            </Typography>

            {/* Description */}
            <Typography
              variant="body2"
              sx={{ textAlign: "center", marginBottom: 3 }}
            >
              Choose a date to find users who signed up from that day onward.
              For example, selecting "Yesterday" will show users who registered
              from that date.
            </Typography>

            {/* Date Picker and Clear Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2, // Space between the DatePicker and ClearButton
              }}
            >
              {/* Clear Button */}
              <IconButton
                onClick={() => setFilters({ ...filters, signUpFilter: null })}
                disabled={isDateRangeDisabled}
                aria-label="clear sign-up date"
              >
                <ClearIcon />
              </IconButton>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {" "}
                <Box>
                  {" "}
                  <DatePicker
                    selected={filters.signUpFilter}
                    onChange={(date: Date | null) =>
                      setFilters({ ...filters, signUpFilter: date })
                    }
                    customInput={<CustomInputSignUpDate />}
                    disabled={isDateRangeDisabled}
                  />{" "}
                </Box>{" "}
              </LocalizationProvider>
            </Box>
          </Paper>
          <Card
            sx={{
              width: "100%",
              padding: 1,
              borderRadius: "8px",
              boxShadow: 3,
              marginBottom: 2,
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 2,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                App Version Filter
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  marginBottom: 2,
                  color: "text.secondary",
                }}
              >
                Use the version range filter to find users based on their app
                version. You can specify a minimum and/or maximum version to
                narrow down your search. One of them (either min or max) can be
                left optional depending on your needs.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Max Version Input */}
                <Paper
                  sx={{
                    width: "48%",
                    padding: 1,
                    borderRadius: "4px",
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    value={filters.maxVersion}
                    onChange={(e) =>
                      setFilters((prevFilters: FilterInterface) => ({
                        ...prevFilters,
                        maxVersion: e.target.value,
                      }))
                    }
                    placeholder="Max Version"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Paper>

                {/* Min Version Input */}
                <Paper
                  sx={{
                    width: "48%",
                    padding: 1,
                    borderRadius: "4px",
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    value={filters.minVersion}
                    onChange={(e) =>
                      setFilters((prevFilters: FilterInterface) => ({
                        ...prevFilters,
                        minVersion: e.target.value,
                      }))
                    }
                    placeholder="Min Version"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Paper>
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: "100%",
              padding: 2,
              borderRadius: "8px",
              boxShadow: 3,
              marginBottom: 2,
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 2,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                BMI range filter
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  marginBottom: 2,
                  color: "text.secondary",
                }}
              >
                Use the BMI (Body Mass Index) range filter to find users based
                on their BMI. You can specify a minimum and/or maximum BMI to
                narrow down your search. One of them (either min or max) can be
                left optional depending on your needs.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Maximum BMI Input */}
                <Paper
                  sx={{
                    width: "48%",
                    padding: 1,
                    borderRadius: "4px",
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Max Bmi"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Paper>

                {/* Minimum BMI Input */}
                <Paper
                  sx={{
                    width: "48%",
                    padding: 1,
                    borderRadius: "4px",
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Min Bmi"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Paper>
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: "100%",
              padding: 2,
              borderRadius: "8px",
              boxShadow: 3,
              marginBottom: 2,
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 2,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Age Range Filter
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  marginBottom: 2,
                  color: "text.secondary",
                }}
              >
                Use the age range filter to find users based on their age. You
                can specify a minimum and/or maximum age to narrow down your
                search. One of them (either min or max) can be left optional
                depending on your needs.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Maximum Age Input */}
                <Paper
                  sx={{
                    width: "48%",
                    padding: 1,
                    borderRadius: "4px",
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    value={filters.maximumAge}
                    onChange={(e) =>
                      setFilters((prevFilters: FilterInterface) => ({
                        ...prevFilters,
                        maximumAge: e.target.value,
                      }))
                    }
                    placeholder="Maximum Age"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Paper>

                {/* Minimum Age Input */}
                <Paper
                  sx={{
                    width: "48%",
                    padding: 1,
                    borderRadius: "4px",
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    value={filters.minimumAge}
                    onChange={(e) =>
                      setFilters((prevFilters: FilterInterface) => ({
                        ...prevFilters,
                        minimumAge: e.target.value,
                      }))
                    }
                    placeholder="Minimum Age"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Paper>
              </Box>
            </CardContent>
          </Card>
          <Paper sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{ marginBottom: 1, textAlign: "center", fontWeight: "bold" }}
            >
              Device Filter
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginBottom: 2,
                color: "text.secondary",
                textAlign: "center",
              }}
            >
              Use this filter to select the device type you want to filter users
              by. Choose between iOS and Android devices to narrow down the
              results.
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={filters.deviceType}
              exclusive
              onChange={(event, newType) =>
                setFilters((prevFilters: any) => ({
                  ...prevFilters,
                  deviceType: newType,
                }))
              } // Use an anonymous function to pass the new type
              aria-label="Filter by type"
              sx={{ width: "100%" }} // This makes the entire button group take full width of its container
            >
              <ToggleButton value="ios" sx={{ width: "50%" }}>
                Ios
              </ToggleButton>
              <ToggleButton value="android" sx={{ width: "50%" }}>
                Android
              </ToggleButton>
            </ToggleButtonGroup>
          </Paper>
          <Paper sx={{ padding: 2, width: "95%" }}>
            <Typography
              variant="h6"
              sx={{ marginBottom: 1, textAlign: "center", fontWeight: "bold" }}
            >
              Gender Filter
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginBottom: 2,
                color: "text.secondary",
                textAlign: "center",
              }}
            >
              Use this filter to select the gender you want to filter users by
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={filters.genderType} // Assuming genderType is a key in the filters state
              exclusive
              onChange={(event, newType) =>
                setFilters((prevFilters: any) => ({
                  ...prevFilters,
                  genderType: newType,
                }))
              }
              aria-label="Filter by type"
              sx={{ width: "100%" }} // This makes the entire button group take full width of its container
            >
              <ToggleButton value="Male" sx={{ width: "50%" }}>
                Male
              </ToggleButton>
              <ToggleButton value="Female" sx={{ width: "50%" }}>
                Female
              </ToggleButton>
            </ToggleButtonGroup>
          </Paper>
          <Paper sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{ marginBottom: 1, textAlign: "center", fontWeight: "bold" }}
            >
              Goal Filter
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginBottom: 2,
                color: "text.secondary",
                textAlign: "center",
              }}
            >
              Use this filter to select one or more user targets. You can choose
              from multiple target options to refine your search based on
              specific user goals.
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={filters.goalType} // Assuming goalType is a key in the filters state
              onChange={(event, newTypes) =>
                setFilters((prevFilters: any) => ({
                  ...prevFilters,
                  goalType: newTypes.length ? newTypes : [],
                }))
              }
              aria-label="Filter by type"
              sx={{ width: "100%" }} // This makes the entire button group take full width of its container
            >
              <ToggleButton
                value="Lose Weight and Gain Muscle"
                sx={{ width: "25%", fontSize: 12 }}
              >
                Lose Weight and Gain Muscle
              </ToggleButton>
              <ToggleButton
                value="Lose Weight"
                sx={{ width: "25%", fontSize: 12 }}
              >
                Lose Weight
              </ToggleButton>
              <ToggleButton
                value="Lifestyle Improvements"
                sx={{ width: "25%", fontSize: 12 }}
              >
                Lifestyle Improvements
              </ToggleButton>
              <ToggleButton
                value="Gain Muscle"
                sx={{ width: "25%", fontSize: 12 }}
              >
                Gain Muscle
              </ToggleButton>
            </ToggleButtonGroup>
          </Paper>
          <Box sx={{ width: "100%" }}>
            {/* Top-level Subscription Filter with Checkbox */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Checkbox
                checked={
                  !!filterSettings.subscriptionCheckBox &&
                  !!filterSettings.freePremiumCheckBox &&
                  !!filterSettings.freeTrialCheckBox &&
                  !!filterSettings.paidCheckBox
                }
                onChange={handleTopLevelCheckbox}
                color="primary"
              />
              <Accordion
                expanded={expanded}
                onChange={handleAccordionChange}
                sx={{ flexGrow: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subscription-content"
                  id="subscription-header"
                >
                  <Typography>Subscription</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Filters for Subscription */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {/* Subscription Filter */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.subscriptionCheckBox}
                        onChange={(event) =>
                          handleCheckbox(
                            event,
                            "subscriptionFilter",
                            "subscriptionCheckBox"
                          )
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={filters.subscriptionFilter ? "subscription" : ""}
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChange(
                            event,
                            newValue,
                            "subscription"
                          );
                        }}
                        aria-label="Filter by subscription"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton
                          value="subscription"
                          sx={{ width: "100%" }}
                        >
                          Premium
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.freePremiumCheckBox}
                        onChange={(event) =>
                          handleCheckbox(
                            event,
                            "freePremiumFilter",
                            "freePremiumCheckBox"
                          )
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={filters.freePremiumFilter ? "freePremium" : ""}
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChange(
                            event,
                            newValue,
                            "freePremium"
                          );
                        }}
                        aria-label="Filter by Free Premium"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton
                          value="freePremium"
                          sx={{ width: "100%" }}
                        >
                          Free Premium
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.paidCheckBox}
                        onChange={(event) =>
                          handleCheckbox(event, "paidFilter", "paidCheckBox")
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={filters.paidFilter ? "paid" : ""}
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChange(event, newValue, "paid");
                        }}
                        aria-label="Filter by paid"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton value="paid" sx={{ width: "100%" }}>
                          Paid
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.freeTrialCheckBox}
                        onChange={(event) =>
                          handleCheckbox(
                            event,
                            "freeTrialFilter",
                            "freeTrialCheckBox"
                          )
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={filters.freeTrialFilter ? "freeTrial" : ""}
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChange(
                            event,
                            newValue,
                            "freeTrial"
                          );
                        }}
                        aria-label="Filter by Free Trial"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton value="freeTrial" sx={{ width: "100%" }}>
                          Free Trial
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            {/* Top-level Subscription Filter with Checkbox */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Checkbox
                checked={
                  !!filterSettings.heardFamilyCheckBox &&
                  !!filterSettings.heardSocialMediaCheckBox &&
                  !!filterSettings.heardCoWorkerCheckBox &&
                  !!filterSettings.heardFriendCheckBox &&
                  !!filterSettings.heardStoreSearchCheckBox &&
                  !!filterSettings.heardOtherCheckBox
                }
                onChange={handleHeardAboutUsCheckbox}
                color="primary"
              />
              <Accordion
                expanded={heardFromrmExpand}
                onChange={handleExpansationForheardFromrm}
                sx={{ flexGrow: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subscription-content"
                  id="subscription-header"
                >
                  <Typography>Heard Form</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Filters for Subscription */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {/* Subscription Filter */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.heardCoWorkerCheckBox}
                        onChange={(event) =>
                          handleCheckboxForHeard(
                            event,
                            "heardCoWorkerFilter",
                            "heardCoWorkerCheckBox"
                          )
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={
                          filters.heardCoWorkerFilter ? "heardCoWorker" : ""
                        }
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChangeForHeardFrom(
                            event,
                            newValue,
                            "heardCoWorker"
                          );
                        }}
                        aria-label="Filter by heardCoWorker"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton
                          value="heardCoWorker"
                          sx={{ width: "100%" }}
                        >
                          Co Worker
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.heardSocialMediaCheckBox}
                        onChange={(event) =>
                          handleCheckboxForHeard(
                            event,
                            "heardSocialMediaFilter",
                            "heardSocialMediaCheckBox"
                          )
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={
                          filters.heardSocialMediaFilter
                            ? "heardSocialMedia"
                            : ""
                        }
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChangeForHeardFrom(
                            event,
                            newValue,
                            "heardSocialMedia"
                          );
                        }}
                        aria-label="Filter by heardSocialMedia"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton
                          value="heardSocialMedia"
                          sx={{ width: "100%" }}
                        >
                          Social Media
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.heardFamilyCheckBox}
                        onChange={(event) =>
                          handleCheckboxForHeard(
                            event,
                            "heardFamilyFilter",
                            "heardFamilyCheckBox"
                          )
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={filters.heardFamilyFilter ? "heardFamily" : ""}
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChangeForHeardFrom(
                            event,
                            newValue,
                            "heardFamily"
                          );
                        }}
                        aria-label="Filter by heardFamily"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton
                          value="heardFamily"
                          sx={{ width: "100%" }}
                        >
                          Family
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.heardFriendCheckBox}
                        onChange={(event) =>
                          handleCheckboxForHeard(
                            event,
                            "heardFriendFilter",
                            "heardFriendCheckBox"
                          )
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={filters.heardFriendFilter ? "heardFriend" : ""}
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChangeForHeardFrom(
                            event,
                            newValue,
                            "heardFriend"
                          );
                        }}
                        aria-label="Filter by FheardFriend"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton
                          value="heardFriend"
                          sx={{ width: "100%" }}
                        >
                          Friend
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.heardStoreSearchCheckBox}
                        onChange={(event) =>
                          handleCheckboxForHeard(
                            event,
                            "heardStoreSearchFilter",
                            "heardStoreSearchCheckBox"
                          )
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={
                          filters.heardStoreSearchFilter
                            ? "heardStoreSearch"
                            : ""
                        }
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChangeForHeardFrom(
                            event,
                            newValue,
                            "heardStoreSearch"
                          );
                        }}
                        aria-label="Filter by heardStoreSearch"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton
                          value="heardStoreSearch"
                          sx={{ width: "100%" }}
                        >
                          Store Search
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Checkbox
                        checked={!!filterSettings.heardOtherCheckBox}
                        onChange={(event) =>
                          handleCheckboxForHeard(
                            event,
                            "heardOtherFilter",
                            "heardOtherCheckBox"
                          )
                        }
                        color="primary"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={filters.heardOtherFilter ? "heardOther" : ""}
                        exclusive
                        onChange={(event, newValue) => {
                          handleToggleButtonChangeForHeardFrom(
                            event,
                            newValue,
                            "heardOther"
                          );
                        }}
                        aria-label="Filter by heardOther"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton value="heardOther" sx={{ width: "100%" }}>
                          Other
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                textAlign: "center",
                marginBottom: 1, // Adds space between text and controls
              }}
            >
              Check the box to show users with canceled subscriptions. Turn off
              the toggle to show users without canceled subscriptions.
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Checkbox
                checked={!!filterSettings.subscriptionCanceledCheckBox}
                onChange={(event) =>
                  handleCheckbox(
                    event,
                    "subscriptionCanceledFilter",
                    "subscriptionCanceledCheckBox"
                  )
                }
                color="primary"
              />
              <ToggleButtonGroup
                color="primary"
                value={
                  filters.subscriptionCanceledFilter
                    ? "subscriptionCanceled"
                    : ""
                }
                exclusive
                onChange={(event, newValue) => {
                  handleToggleButtonChange(
                    event,
                    newValue,
                    "subscriptionCanceled"
                  );
                }}
                aria-label="Filter by subscriptionCanceled"
                sx={{ width: "100%" }}
              >
                <ToggleButton
                  value="subscriptionCanceled"
                  sx={{ width: "100%" }}
                >
                  Subscription Canceled
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.weighingScaleCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "weighingScaleFilter",
                  "weighingScaleCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.weighingScaleFilter ? "weighingScale" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "weighingScale");
              }}
              aria-label="Filter by weighingScale"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="weighingScale" sx={{ width: "100%" }}>
                Weighing Scale
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.freePremiumOverCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "freePremiumOverFilter",
                  "freePremiumOverCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.freePremiumOverFilter ? "freePremiumOver" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "freePremiumOver");
              }}
              aria-label="Filter by freePremiumOver"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="freePremiumOver" sx={{ width: "100%" }}>
                Free Premium Over
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.hasGymMembershipCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "hasGymMembershipFilter",
                  "hasGymMembershipCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.hasGymMembershipFilter ? "hasGymMembership" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "hasGymMembership");
              }}
              aria-label="Filter by hasGymMembership"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="hasGymMembership" sx={{ width: "100%" }}>
                Gym Membership
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.initialQuotaCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "initialQuotaFilter",
                  "initialQuotaCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.initialQuotaFilter ? "initialQuota" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "initialQuota");
              }}
              aria-label="Filter by initialQuota"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="initialQuota" sx={{ width: "100%" }}>
                Initital Quota Over
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              ignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.openSupportMessageCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "openSupportMessage",
                  "openSupportMessageCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.openSupportMessage ? "openSupportMessage" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "openSupportMessage");
              }}
              aria-label="Filter by openSupportMessage"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="openSupportMessage" sx={{ width: "100%" }}>
                Open Support Message
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.notesCheckBox}
              onChange={(event) =>
                handleCheckbox(event, "notesFilter", "notesCheckBox")
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.notesFilter ? "notes" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "notes");
              }}
              aria-label="Filter by notes"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="notes" sx={{ width: "100%" }}>
                Notes
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.contactNumberCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "contactNumberFilter",
                  "contactNumberCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.contactNumberFilter ? "contactNumber" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "contactNumber");
              }}
              aria-label="Filter by contactNumber"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="contactNumber" sx={{ width: "100%" }}>
                Contact Number
              </ToggleButton>
            </ToggleButtonGroup>
            {filters.contactNumberFilter != undefined && (
              <TextField
                label="Country Code"
                value={filters.countryCode}
                onChange={(e) =>
                  setFilters((prevFilters: FilterInterface) => ({
                    ...prevFilters,
                    countryCode: e.target.value,
                  }))
                }
                sx={{ ml: 1 }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Ensure proper spacing
              width: "100%",
              flexWrap: "wrap", // Prevent breaking on smaller screens
              gap: 2, // Space between elements
            }}
          >
            {/* Checkbox & Toggle Button */}
            <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
              <Checkbox
                checked={!!filterSettings.csvFileCheckBox}
                onChange={(event) =>
                  handleCheckbox(event, "csvFileFilter", "csvFileCheckBox")
                }
                color="primary"
              />
              <ToggleButtonGroup
                color="primary"
                value={filters.csvFileFilter ? "csvFile" : ""}
                exclusive
                onChange={(event, newValue) => {
                  handleToggleButtonChange(event, newValue, "csvFile");
                }}
                aria-label="Filter by csvFile"
                sx={{ width: "100%" }}
              >
                <ToggleButton value="csvFile" sx={{ width: "100%" }}>
                  CSV Filter
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {/* Show File Upload & Name */}
            {filters.csvFileFilter !== undefined && (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, flex: 2 }}
              >
                <TextField
                  label="Field Name"
                  value={csvFieldName}
                  onChange={(e) => setCsvFieldName(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  sx={{ width: "180px" }} // Set a fixed width to prevent layout breaking
                />

                <Input
                  type="file"
                  onChange={handleFileUpload}
                  sx={{ display: "none" }}
                  id="csv-file-input"
                  inputProps={{ accept: ".csv" }}
                />
                <label htmlFor="csv-file-input">
                  <Tooltip title="Make sure to set the correct field name before uploading. Changing it afterward won't affect the results.">
                    <Button variant="contained" component="span">
                      Select CSV File
                    </Button>
                  </Tooltip>
                </label>

                {/* Display file name if available */}
                {fileName && (
                  <Chip
                    label={fileName}
                    color="primary"
                    variant="outlined"
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 2,
                      bgcolor: "rgba(63, 81, 181, 0.1)", // Light background color
                      color: "primary.main",
                    }}
                  />
                )}
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.onboardingCheckBox}
              onChange={(event) =>
                handleCheckbox(event, "onboardingFilter", "onboardingCheckBox")
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.onboardingFilter ? "onboarding" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "onboarding");
              }}
              aria-label="Filter by onboarding"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="onboarding" sx={{ width: "100%" }}>
                Onboarding
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.notificationCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "notificationFilter",
                  "notificationCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.notificationFilter ? "notification" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "notification");
              }}
              aria-label="Filter by notification"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="notification" sx={{ width: "100%" }}>
                Notification
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.messageSeenCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "messageSeenFilter",
                  "messageSeenCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={filters.messageSeenFilter ? "messageSeen" : ""}
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(event, newValue, "messageSeen");
              }}
              aria-label="Filter by messageSeen"
              sx={{ width: "100%" }}
            >
              <ToggleButton value="messageSeen" sx={{ width: "100%" }}>
                Message Seen
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={!!filterSettings.uncheckedUserMessagesCheckBox}
              onChange={(event) =>
                handleCheckbox(
                  event,
                  "uncheckedUserMessagesFilter",
                  "uncheckedUserMessagesCheckBox"
                )
              }
              color="primary"
            />
            <ToggleButtonGroup
              color="primary"
              value={
                filters.uncheckedUserMessagesFilter
                  ? "uncheckedUserMessages"
                  : ""
              }
              exclusive
              onChange={(event, newValue) => {
                handleToggleButtonChange(
                  event,
                  newValue,
                  "uncheckedUserMessages"
                );
              }}
              aria-label="Filter by uncheckedUserMessages"
              sx={{ width: "100%" }}
            >
              <ToggleButton
                value="uncheckedUserMessages"
                sx={{ width: "100%" }}
              >
                Unchecked User Messages
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Country Filter */}

          <ToggleButton
            value="check"
            selected={countryDropdownOpen}
            onChange={() => setCountryDropdownOpen(!countryDropdownOpen)}
            sx={{ width: "100%" }}
          >
            Countries
            {countryDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ToggleButton>

          {/* Collapsible Country Dropdown */}

          <Collapse in={countryDropdownOpen}>
            <Box
              sx={{
                maxHeight: 200,
                overflowY: "scroll",
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                padding: 1,
                marginY: 1,
                width: "100%", // Adjust this to take more width
                backgroundColor: "#f0f0f0",
              }}
            >
              {countryLoading ? (
                <Container>
                  <CircularProgress />
                </Container>
              ) : (
                <FormControl component="fieldset">
                  <FormGroup>
                    {countries.map((country: any, index: any) => {
                      const isChecked =
                        filters.checkBoxCountries.includes(country);
                      const isCrossed =
                        filters.uncheckCountries.includes(country);

                      return (
                        <Box key={index} display="flex" alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isChecked}
                                onChange={() =>
                                  handleCountryChange(country, "checkbox")
                                }
                                value={country}
                                style={{
                                  backgroundColor: isChecked
                                    ? "skyblue"
                                    : "transparent",
                                }}
                              />
                            }
                            label={country}
                          />
                          <IconButton
                            onClick={() =>
                              handleCountryChange(country, "cross")
                            }
                            aria-label={`toggle ${country}`}
                            style={{
                              color: isCrossed ? "red" : "black",
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </FormGroup>
                </FormControl>
              )}
            </Box>
          </Collapse>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ToggleButton
              onClick={applyFilter}
              value="onboarding"
              sx={{ width: "50%" }}
            >
              Apply
            </ToggleButton>
            <ToggleButton
              value="reset"
              sx={{ width: "50%" }}
              onClick={() => reseting()}
            >
              Reset
            </ToggleButton>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

export default memo(SortingModal);
