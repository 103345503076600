/** @format */

import { act, useEffect, useRef, useState } from "react";

import {
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Container,
  Button,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { accountService } from "../services";
import { userService } from "../services/userdetails.service";
import { memo } from "react";
import SortingModal from "../modules/SortingModal";
import GppGoodIcon from "@mui/icons-material/GppGood";
import SubscriptionShowingModal from "../modules/SubscriptionShowingModal";
import NotesIcon from "@mui/icons-material/Description";
import InfiniteScroll from "react-infinite-scroller";
import { FilterInterface, FilterSettings } from "../interface/FilterInterface";
import { FilterInitialState, initialFilterSettings } from "../utils/FilterUtis";
import { CSVLink } from "react-csv";
import { AppState, SelectedUserInterface } from "../interface/User";
import inititalValueForSqliteDb from "../utils/UserDetails";
interface Acitivity {
  date: Date | null;
  activityType: String;
}
interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}
const SideNavbar = ({
  setSelectedUser,
  selectedUser,
  changedUser,

  setAllUsers,
  dataApiCall,
  totalNumberOfUsers,
  setTotalNumberOfUsers,
  totalUsers,
  settotalUsers,
  allUsers,
}: {
  setSelectedUser: any;

  selectedUser: any;
  changedUser: any;
  setUserDataLoading: (type: boolean) => void;
  setAllUsers: any;
  dataApiCall: (value: SelectedUserInterface) => void;
  setAppState: (value: AppState) => void;
  totalNumberOfUsers: any;
  setTotalNumberOfUsers: (type: number) => void;
  allUsers: any[];
  totalUsers: any[];
  settotalUsers: any;
}) => {
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [userLoading, setUserLoading] = useState(false);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [fixedUsers, setFixedUsers] = useState<any[]>([]);
  /// State for the filters settings ( like checkbox turn on off )---------------------------------/
  const [filterSettings, setFilterSettings] = useState<FilterSettings>(
    initialFilterSettings
  );
  const [subscribedUserDetails, setSubscribedUserDetails] =
    useState<SelectedUserInterface | null>(null);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false); // new state to track loading more users
  const listInnerRef = useRef<HTMLUListElement>(null);
  const [searchBarActive, setSearchBarActive] = useState(false);
  const [firstTimeLoad, setFirstTimeLoad] = useState(false);

  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(false);
  const [numberofCalls, setNumberOfCalls] = useState(1);
  ///State for the filters -------------------------------------------------------------------------/
  const [filters, setFilters] = useState<FilterInterface>(FilterInitialState);
  const [fixedUserNumber, setFixedUserNumber] = useState(0);

  const [allCsvUsers, setAllCsvUsers] = useState<any[]>([]);

  const [loadingCsv, setLoadingCsv] = useState(false);

  const csvLinkRef = useRef<any>();

  const fetchUsers = async () => {
    setFirstTimeLoad(true);
    setIsLoadingMore(false);
    setUserLoading(true);
    try {
      const user = accountService.userValue;
      let numberofCalls = 1;
      const params = {
        numberofCalls: numberofCalls,
        userGroupNumber: 20,
        trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
        activity: null,
        dateRange: null,
        signUpFilter: null,
      };

      const res = await accountService.getAllUsers(params);

      let { users, userCount } = res;

      const sortedUsers = users.sort((a: any, b: any) => {
        if (a.messageSeen === b.messageSeen) {
          return 0;
        }
        return a.messageSeen ? -1 : 1;
      });

      // Set the sorted users
      setAllUsers(sortedUsers);

      setFixedUsers(sortedUsers);
      settotalUsers(sortedUsers);
      setTotalNumberOfUsers(userCount);
      setFixedUserNumber(userCount);
      setUserLoading(false);
      setHasMoreUsers(true);
    } catch (error) {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (changedUser !== undefined) {
      // Find the index of the matching user in filteredUsers
      const userIndex = totalUsers.findIndex(
        (user: any) => user._id === changedUser._id
      ) as number;

      //     // If the user is found, update the specific user in filteredUsers
      if (userIndex !== -1) {
        const updatedFilteredUsers = [...totalUsers] as any[];
        updatedFilteredUsers[userIndex] = changedUser;

        // Set the updated filteredUsers state
        settotalUsers(updatedFilteredUsers);
      }
    }
  }, [changedUser, allUsers]);
  const fetchMoreUsers = async (
    numberofCalls: number,
    filters: FilterInterface,
    hasMoreUsers: boolean
  ) => {
    // Only fetch more if we previously determined there are more users to fetch
    if (!hasMoreUsers) {
      return;
    }
    setIsLoadingMore(true);
    // Implement logic to fetch more users based on the next request number
    // This is similar to your existing fetchUsers but increments reqestCallForUsers and fetches additional users
    try {
      const user = accountService.userValue;

      const params = {
        numberofCalls: numberofCalls + 1,
        userGroupNumber: 20,
        deviceType: filters.deviceType, // Add device type if active
        onboarding: filters.onboardingFilter, // Add onboarding status if active
        subscription: filters.subscriptionFilter, // Add subscription status if active
        trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
        countries: filters.checkBoxCountries, // Include selected countries in the request
        notification: filters.notificationFilter,
        maxVersion: filters.maxVersion,
        minVersion: filters.minVersion,
        genderType: filters.genderType,
        goalType: filters.goalType,
        activeType: filters.activeType,
        ...filters.activity,
        ...filters.dateRange,
        contactNumber: filters.contactNumberFilter,
        notes: filters.notesFilter,
        uncheckedCountries: filters.uncheckCountries,
        messageSeen: filters.messageSeenFilter,
        countryCode: filters.countryCode,
        csvFile: filters.csvFileFilter,
        distinctIds: filters.distinctIds,
        openSupportMessage: filters.openSupportMessage,
        minimumAge: filters.minimumAge,
        maximumAge: filters.maximumAge,
        maximumBmi: filters.maximumBmi,
        minimumBmi: filters.minimumBmi,
        freePremium: filters.freePremiumFilter,
        freeTrial: filters.freeTrialFilter,
        paidFilter: filters.paidFilter,
        signUpFilter: filters.signUpFilter,
        subscriptionCanceledFilter: filters.subscriptionCanceledFilter,
        initialQuotaFilter: filters.initialQuotaFilter,
        hasGymMembershipFilter: filters.hasGymMembershipFilter,
        freePremiumOverFilter: filters.freePremiumOverFilter,
        weighingScaleFilter: filters.weighingScaleFilter,
        uncheckedUserMessagesFilter: filters.uncheckedUserMessagesFilter,
        heardFromFilter: {
          coworker: filters.heardCoWorkerFilter,
          socialMedia: filters.heardSocialMediaFilter,
          family: filters.heardFamilyFilter,
          friend: filters.heardFriendFilter,
          storeSearch: filters.heardStoreSearchFilter,
          other: filters.heardOtherFilter,
        },
      };

      const additionalUsers = await accountService.getAllUsers(params);

      let { users, userCount } = additionalUsers;
      if (users.length > 0) {
        setAllUsers((prevUsers: any) => [...prevUsers, ...users]);
        // setFixedUsers((prevUsers: any) => [...prevUsers, ...users]);
        settotalUsers((prevUsers: any) => [...prevUsers, ...users]);
        setNumberOfCalls((prevNumber) => prevNumber + 1);
      } else {
        setNumberOfCalls((prevNumber) => prevNumber + 1);

        setHasMoreUsers(false);
      }
    } catch (error) {
      console.error("Failed to load more users:", error);
    }

    setIsLoadingMore(false);
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  useEffect(() => {
    if (fixedUsers.length === 0) return;

    // Set up a timer for debouncing user input
    const timerId = setTimeout(async () => {
      // If search text is empty, reset to fixed users
      if (!searchText.trim()) {
        settotalUsers(fixedUsers);
        setTotalNumberOfUsers(fixedUserNumber);
        setHasMoreUsers(true);
        return;
      }

      setUserLoading(true);

      try {
        // Determine which service to call based on user role
        const fetchUsersPromise = userService.getSearchUsersOfAdmin(
          searchText,
          filters
        );

        const fetchedUsers = await fetchUsersPromise;

        // Update state based on fetched data
        settotalUsers(fetchedUsers);
        setTotalNumberOfUsers(fetchedUsers.length);
        setHasMoreUsers(false);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch users.");
      } finally {
        setUserLoading(false);
      }
    }, 1000); // Debounce delay

    // Cleanup timer when unmounted or dependencies change
    return () => clearTimeout(timerId);
  }, [searchText]);

  // Search input handler with error reset
  const handleSearchChange = (event: any) => {
    if (event.target.value !== "") {
      //// searchbar is not empty so the user appears with the searchbar searching
      /// will be added with the filter ones so we will make searchbaractive state
      /// true just to track that
      setSearchBarActive(true);
      setSearchText(event.target.value);
      setError(null); // Reset error when user types
    } else {
      setSearchBarActive(false);
      //// searbacj is empty so no user will be added based on searchbar
      setSearchText(event.target.value);
      setError(null); // Reset error when user types
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  function onClose() {
    setSubscriptionModalOpen(!subscriptionModalOpen);
  }

  const loadMoreHandler = async (page: number) => {
    if (!hasMoreUsers) {
      return;
    }
    if (firstTimeLoad) {
      if (!isLoadingMore) {
        // Call your fetchMoreUsers function here
        fetchMoreUsers(numberofCalls, filters, hasMoreUsers);
      }
    } else {
      //await fetchUsers();
    }
  };

  function resetCheckBoxFunctionaltiy() {}
  const triggerCsvDownload = () => {
    if (csvLinkRef.current && allCsvUsers.length > 0) {
      csvLinkRef.current.link.click();
    }
  };
  // Helper function to calculate age from year of birth
  const calculateAge = (yob: any) => {
    const currentYear = new Date().getFullYear();
    return currentYear - yob;
  };
  const getCsvUsers = () => {
    if (!loadingCsv) {
      setLoadingCsv(true);

      const user = accountService.userValue;

      const params = {
        deviceType: filters.deviceType,
        onboarding: filters.onboardingFilter,
        subscription: filters.subscriptionFilter,
        trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode,
        countries: filters.checkBoxCountries,
        notification: filters.notificationFilter,
        maxVersion: filters.maxVersion,
        minVersion: filters.minVersion,
        genderType: filters.genderType,
        goalType: filters.goalType,
        activeType: filters.activeType,
        ...filters.activity,
        ...filters.dateRange,
        contactNumber: filters.contactNumberFilter,
        notes: filters.notesFilter,
        uncheckedCountries: filters.uncheckCountries,
        messageSeen: filters.messageSeenFilter,
        countryCode: filters.countryCode,
        allFields: true,
        csvFile: filters.csvFileFilter,
        distinctIds: filters.distinctIds,
        minimumAge: filters.minimumAge,
        maximumAge: filters.maximumAge,
        maximumBmi: filters.maximumBmi,
        minimumBmi: filters.minimumBmi,
        freePremium: filters.freePremiumFilter,
        freeTrial: filters.freeTrialFilter,
        paidFilter: filters.paidFilter,
        signUpFilter: filters.signUpFilter,
        subscriptionCanceledFilter: filters.subscriptionCanceledFilter,
        initialQuotaFilter: filters.initialQuotaFilter,
        hasGymMembershipFilter: filters.hasGymMembershipFilter,
        weighingScaleFilter: filters.weighingScaleFilter,
        heardFromFilter: {
          coworker: filters.heardCoWorkerFilter,
          socialMedia: filters.heardSocialMediaFilter,
          family: filters.heardFamilyFilter,
          friend: filters.heardFriendFilter,
          storeSearch: filters.heardStoreSearchFilter,
          other: filters.heardOtherFilter,
        },
        uncheckedUserMessagesFilter: filters.uncheckedUserMessagesFilter,
      };

      accountService
        .getAllUsers(params)
        .then((res) => {
          let { users } = res;

          const filteredUsers = users.map((user: any) => {
            let device = user?.metadata?.os;
            let platform = device.match(/(android|ios)/i)?.[0];
            const basicUserInfo = {
              email: user.email,
              phone: user.contactNumber, // Use correct field for the phone
              id: user._id,
              name: user.name,
              ct: user.metadata.city,
              country: user.metadata.country,
              yob: user.yob, // Assuming this is stored in `dateOfBirth`
              gen: user.gender,
              age: calculateAge(user.yob), // Calculate age from the year of birth
              goal: user.targetActivity,
              height: user.height,
              device: platform,
            };

            // Check if `onboardingChatSummary` exists and process it if in the expected format
            if (user.onboardingChatSummary) {
              const onboardingChatSummary = user.onboardingChatSummary;

              // Ensure the structure matches the expected format
              if (
                onboardingChatSummary.hasOwnProperty("medical conditions") &&
                onboardingChatSummary.hasOwnProperty("allergies") &&
                onboardingChatSummary.hasOwnProperty("dietary_restrictions") &&
                onboardingChatSummary.hasOwnProperty("preferred_diet") &&
                onboardingChatSummary.hasOwnProperty(
                  "experience_with_training"
                ) &&
                onboardingChatSummary.hasOwnProperty("ideal_fitness_goals")
              ) {
                // Extract values if all keys are present and in expected format
                const medical_conditions =
                  onboardingChatSummary["medical conditions"]?.[0]?.name ||
                  "None";
                const allergies =
                  onboardingChatSummary["allergies"]?.join(", ") || "None";
                const dietary_restrictions =
                  onboardingChatSummary["dietary_restrictions"]?.join(", ") ||
                  "None";
                const preferred_diet =
                  onboardingChatSummary["preferred_diet"]?.join(", ") || "None";
                const experience_with_training =
                  onboardingChatSummary["experience_with_training"] || "None";
                const ideal_fitness_goals =
                  onboardingChatSummary["ideal_fitness_goals"] || "None";

                const onboardingDetails = {
                  medical_conditions,
                  allergies,
                  dietary_restrictions,
                  preferred_diet,
                  experience_with_training,
                  ideal_fitness_goals,
                };

                return { ...basicUserInfo, ...onboardingDetails };
              } else {
                // Skip user if the format doesn't match
                return basicUserInfo;
              }
            }

            // If there is no `onboardingChatSummary`, return just basicUserInfo
            return basicUserInfo;
          });

          // Update the state with filtered users
          setAllCsvUsers(filteredUsers);
          setLoadingCsv(false); // Set loading to false after state update
        })
        .catch(() => {
          setLoadingCsv(false);
        });
    }
  };

  // UseEffect to trigger CSV download after users are set
  useEffect(() => {
    if (allCsvUsers.length > 0) {
      csvLinkRef.current.link.click(); // Trigger download
    }
  }, [allCsvUsers]); // Run effect when allCsvUsers changes
  return (
    <>
      <Grid item xs={12}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <div
            style={{
              width: "55%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              type="search"
              variant="outlined"
              fullWidth
              onChange={handleSearchChange}
              value={searchText}
              placeholder="Search users"
              disabled={userLoading}
            />
          </div>
          <div
            style={{
              width: "45%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginRight: 15 }}
              onClick={handleOpenModal}
              disabled={userLoading}
            >
              Sort
            </Button>
            {userLoading ? (
              <div>
                <CircularProgress style={{ fontSize: 10 }} />
              </div>
            ) : (
              <>
                <Button onClick={getCsvUsers} disabled={loadingCsv}>
                  {loadingCsv ? "Loading csv..." : totalNumberOfUsers}
                </Button>
                <CSVLink
                  data={allCsvUsers}
                  filename="users.csv"
                  className="hidden"
                  ref={csvLinkRef}
                />
              </>
            )}
          </div>
        </div>
      </Grid>
      {modalOpen ? (
        <SortingModal
          modalOpen={modalOpen}
          filterSettings={filterSettings}
          setFilterSettings={setFilterSettings}
          setModalOpen={setModalOpen}
          totalUsers={totalUsers}
          setTotalUsers={settotalUsers}
          fixedUsers={fixedUsers}
          listInnerRef={listInnerRef}
          setNumberOfCalls={setNumberOfCalls}
          filters={filters}
          setFilters={setFilters}
          setTotalNumberOfUsers={setTotalNumberOfUsers}
          setUserLoading={setUserLoading}
          resetCheckBoxFunctionaltiy={resetCheckBoxFunctionaltiy}
          setHasMoreUsers={setHasMoreUsers}
          fetchUsers={fetchUsers}
          searchBarActive={searchBarActive}
          searchText={searchText}
        />
      ) : null}

      <Grid item xs={12}>
        {userLoading ? (
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ marginTop: 35 }} />
          </Container>
        ) : (
          <div style={{ height: "75vh", overflowY: "auto" }}>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMoreHandler}
              hasMore={hasMoreUsers}
              loader={
                <Container
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  key="loader" // Ensure the loader has a key
                >
                  <CircularProgress key="loader" />
                </Container>
              }
              useWindow={false} // Adjust if you're using a specific scrollable container
            >
              <List id="scrollableDiv">
                {totalUsers &&
                  totalUsers.map((user: SelectedUserInterface, index) => (
                    <ListItem
                      key={user._id} // Use a unique identifier here
                      selected={user._id === selectedUser?._id}
                      onClick={() => {
                        setSelectedUser(user);
                      }}
                      sx={{
                        cursor: "pointer",
                        bgcolor:
                          user._id === selectedUser?._id ? "grey.300" : "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "calc(100% - 40px)",
                        }}
                      >
                        {/* Primary and Secondary Text */}
                        <ListItemText
                          primary={user.name || "No Name"}
                          secondary={user.email}
                          sx={{
                            minWidth: "0",
                            flexGrow: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        />

                        {/* Blue Dot */}
                        {user.messageSeen && (
                          <span
                            style={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              backgroundColor: "blue",
                              marginLeft: "10px",
                              flexShrink: 0,
                            }}
                          />
                        )}
                      </div>
                      {/* Subscription Icon */}
                      <div style={{ width: "10px", flexShrink: 0 }}>
                        {user.subscription ? (
                          <GppGoodIcon
                            style={{
                              color: "#0D47A1",
                              cursor: "pointer",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setSubscribedUserDetails(user);
                              setSubscriptionModalOpen(!subscriptionModalOpen);
                            }}
                          />
                        ) : null}
                      </div>
                      <div style={{ flexShrink: 0 }}>
                        {!user.subscription && user.freePremiumOver ? (
                          <GppGoodIcon
                            style={{
                              color: "#9E9E9E",
                              cursor: "pointer",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setSubscribedUserDetails(user);
                              setSubscriptionModalOpen(!subscriptionModalOpen);
                            }}
                          />
                        ) : null}
                      </div>
                    </ListItem>
                  ))}
              </List>
            </InfiniteScroll>
          </div>
        )}
      </Grid>
      {/* <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteUser(userToDelete)}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {modalMsg === "" ? null : <h4>Not able to delete the user</h4>}
        </div>
      </Dialog> */}
      {subscriptionModalOpen && (
        <SubscriptionShowingModal
          subscriptionModalOpen={subscriptionModalOpen}
          onClose={onClose}
          selectedUser={subscribedUserDetails}
        />
      )}
    </>
  );
};

export default memo(SideNavbar);
