import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

interface CustomPieChartProps {
  title: string;
  data: { name: string; value: number }[];
}

const COLORS = ["#1E88E5", "#D32F2F", "#43A047", "#FBC02D", "#8E24AA"];

const CustomTooltip = ({ active, payload, coordinate }: any) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          left: coordinate.x + 15, // Move right to avoid overlap
          top: coordinate.y + payload[0].index * 15, // Offset Y position dynamically
          padding: "8px",
          backgroundColor: "#fff",
          borderRadius: "6px",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
          pointerEvents: "none",
          zIndex: 10,
        }}
      >
        <Typography fontSize="0.9rem" fontWeight="bold">
          {payload[0].name}: {payload[0].value}
        </Typography>
      </Paper>
    );
  }
  return null;
};

const CustomPieChart: React.FC<CustomPieChartProps> = ({ title, data }) => {
  const filteredData = data.filter((entry) => entry.value > 0);

  return (
    <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {title}
      </Typography>

      {filteredData.length === 0 ? (
        <Box
          sx={{
            height: 240,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography color="gray" fontSize="1rem">
            No Data Available
          </Typography>
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={240}>
          <PieChart>
            <Pie
              data={filteredData.map((entry, index) => ({ ...entry, index }))}
              cx="50%"
              cy="50%"
              outerRadius="75%"
              dataKey="value"
              label
            >
              {filteredData.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: "transparent" }}
            />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
};

export default CustomPieChart;
