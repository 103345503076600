import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Card,
  Avatar,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Container,
  Snackbar,
} from "@mui/material";
import TopAppBar from "../modules/TopAppBar";
import WhatsAppMiddleSection from "../Components/WhatsApp/WhatsAppMiddleSection";
import { accountService } from "../services";
import { FilterInterface } from "../interface/FilterInterface";
import { notificationUtils } from "../utils/Notification";
import { SelectedUserInterface } from "../interface/User";

import { whatsappService } from "../services/whatsapp.service";
import ResultsViewer from "../Components/WhatsApp/TemplateResponse";
import { TemplatesInterface } from "../interface/WhatsAppPageInterface";
import WhatsAppSingleUserChat from "../Components/WhatsApp/WhatsAppSingleUserChat";
import WhatsappSideBar from "../modules/WhatsappSidebar";

const WhatsApp = () => {
  const [showChat, setShowChat] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState("16479560936");

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const [userArray, setUserArray] = useState<any[]>();

  const [userLoading, setUserLoading] = useState(false);

  const [fixedUsers, setFixedUsers] = useState<SelectedUserInterface[]>([]); // Initialize with an empty array
  const [totalUsers, settotalUsers] = useState<SelectedUserInterface[]>([]);
  const [totalCheckItemNumber, setTotalCheckItemNumber] = useState(0);
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState<number>(0);
  const [activeTab, setActiveTab] = useState(0); // State to track the active tab

  const [firstTimeLoad, setFirstTimeLoad] = useState(false);
  const [userWhoseChatIsOpen, setUserWhoseChatIsOpen] = useState<any>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false); // new state to track loading more users
  const listInnerRef = useRef<HTMLUListElement>(null);
  const [numberofCalls, setNumberOfCalls] = useState(2);
  const [selectAll, setSelectAll] = useState(false);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [fixedUserNumber, setFixedUserNumber] = useState(0);
  const [selectedUser, setSelectedUser] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [templateResponse, setTemplateResponse] = useState<any[]>([]);
  const [templates, setTemplates] = useState<TemplatesInterface[]>([]);
  const [inputValue, setInputValue] = useState(""); // State for TextField value
  const [token, setToken] = useState("");
  const [templateDetails, setTemplateDetails] = useState({
    templateName: "",
    templateLanguage: "",
  });

  const [currentConversationId, setCurrentConversationId] = useState<
    string | null
  >(null);
  const handleLoadToken = () => {
    setSnackbarOpen(true);
    setSnackbarMessage("Token has been loaded");
    setToken(inputValue); // Update token state with TextField value
  };
  /// Here is an useeffect for selecting the user and opening the chat section ------/
  useEffect(() => {
    if (userWhoseChatIsOpen) {
      setShowChat(true);
    }
  }, [userWhoseChatIsOpen]);

  const fetchUsers = async () => {
    setUserLoading(true);
    try {
      const user = accountService.userValue;
      let numberofCalls = 1;
      const params = {
        numberofCalls: numberofCalls,
        userGroupNumber: 20,
        trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
        activity: {
          date: null,
          activityType: "",
        },
        dateRange: {
          startDate: null,
          endDate: null,
        },
        signUpFilter: null,
      };

      const res = await accountService.getAllUsers(params);
      let { users, userCount } = res;
      const sortedUsers = users.sort((a: any, b: any) => {
        if (a.messageSeen === b.messageSeen) {
          return 0;
        }
        return a.messageSeen ? -1 : 1;
      });
      setFirstTimeLoad(true);

      // Set the sorted users
      settotalUsers(sortedUsers);
      setFixedUsers(sortedUsers);
      setUserLoading(false);
      setFixedUserNumber(userCount);
      setTotalNumberOfUsers(userCount);
      const loggedUserr = await accountService.userValue;
    } catch (error) {
      setUserLoading(false);
    }
  };
  /////////////// fETCHING THE User if user details is not there----/
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchMoreUsers = async (
    numberofCalls: any,
    filters: FilterInterface,
    hasMoreUsers: boolean
    // Add a new parameter for
  ) => {
    // Only fetch more if we previously determined there are more users to fetch
    if (!hasMoreUsers) {
      return;
    }
    setIsLoadingMore(true);

    // Implement logic to fetch more users based on the next request number
    // This is similar to your existing fetchUsers but increments reqestCallForUsers and fetches additional users
    try {
      const user = accountService.userValue;

      const params = {
        numberofCalls: numberofCalls + 1,
        userGroupNumber: 20,
        deviceType: filters.deviceType, // Add device type if active
        onboarding: filters.onboardingFilter, // Add onboarding status if active
        subscription: filters.subscriptionFilter, // Add subscription status if active
        trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
        countries: filters.checkBoxCountries, // Include selected countries in the request
        notification: filters.notificationFilter,
        maxVersion: filters.maxVersion,
        minVersion: filters.minVersion,
        genderType: filters.genderType,
        goalType: filters.goalType,
        activeType: filters.activeType,
        activity: filters.activity,
        dateRange: filters.dateRange,
        contactNumber: filters.contactNumberFilter,
        notes: filters.notesFilter,
        uncheckedCountries: filters.uncheckCountries,
        messageSeen: filters.messageSeenFilter,
        countryCode: filters.countryCode,
        csvFile: filters.csvFileFilter,
        distinctIds: filters.distinctIds,
        openSupportMessage: filters.openSupportMessage,
        minimumAge: filters.minimumAge,
        maximumAge: filters.maximumAge,
        maximumBmi: filters.maximumBmi,
        minimumBmi: filters.minimumBmi,
        freePremium: filters.freePremiumFilter,
        freeTrial: filters.freeTrialFilter,
        paidFilter: filters.paidFilter,
        signUpFilter: filters.signUpFilter,
        subscriptionCanceledFilter: filters.subscriptionCanceledFilter,
        initialQuotaFilter: filters.initialQuotaFilter,
        hasGymMembershipFilter: filters.hasGymMembershipFilter,
        freePremiumOverFilter: filters.freePremiumOverFilter,
        weighingScaleFilter: filters.weighingScaleFilter,
        heardFromFilter: {
          coworker: filters.heardCoWorkerFilter,
          socialMedia: filters.heardSocialMediaFilter,
          family: filters.heardFamilyFilter,
          friend: filters.heardFriendFilter,
          storeSearch: filters.heardStoreSearchFilter,
          other: filters.heardOtherFilter,
        },
      };

      const additionalUsers = await accountService.getAllUsers(params);

      let { users, userCount } = additionalUsers;
      if (users.length > 0) {
        // setFixedUsers((prevUsers: any) => [...prevUsers, ...users]);
        settotalUsers((prevUsers: any) => [...prevUsers, ...users]);
        setNumberOfCalls((prevNumber) => prevNumber + 1);
        setTotalNumberOfUsers(userCount);
        // Update selectedUserIds state with new user IDs if selectAll is true
        if (selectAll) {
          const newSelectedUsers = users.map((user: any) => ({
            userId: user._id,
            fcmToken: user.fcmToken,
          }));
          setSelectedUserIds((prevSelectedIds) => [
            ...prevSelectedIds,
            ...newSelectedUsers,
          ]);
        }
      } else {
        setHasMoreUsers(false);
        setNumberOfCalls((prevNumber) => prevNumber + 1);
      }
    } catch (error) {
      console.error("Failed to load more users:", error);
    }
    setIsLoadingMore(false);
  };

  const handleNumberChange = (event: any) => {
    setSelectedNumber(event.target.value);
  };

  ///this is the thinguseEffect
  const handleSend = async (data: any) => {
    if (selectedUserIds.length === 0) {
      setSnackbarOpen(true);
      setSnackbarMessage("First select the users");
      return;
    }

    try {
      setLoading(true);

      // Fetch the logged-in user
      const loggedUser = await accountService.userValue;

      // Filter out users who don't have a contact number
      const validUsers = selectedUserIds.filter(
        (user: any) => user.contactNumber
      );

      if (validUsers.length === 0) {
        setSnackbarOpen(true);
        setSnackbarMessage("No valid users with contact numbers.");
        setLoading(false);
        return;
      }

      // Create payload data only for valid users
      const payloadData = validUsers.map((user: any) => ({
        contactNumber: user.contactNumber.replace("+", ""), // Remove the '+' symbol
        user_id: user.userId,
        name: user.name,
        language: data.templateLanguage,
        templateName: data.templateName,
      }));

      // Final payload
      const payload = {
        role: "support",
        support_id: loggedUser.id,
        support_phone_number: selectedNumber,
        data: payloadData,
        token: token,
      };
      console.log("this is the payload");
      console.log(payload);

      const response = await whatsappService.sendTemplateMessage(payload);
      console.log("this is response new");
      console.log(response);

      if (response.success) {
        console.log("went here");

        setTemplateResponse(response.data);
        setSnackbarOpen(true);
        setSnackbarMessage("Response has been fetched");
        ///// Now we will store the template in the database -----------------/
        let templateDetails = {
          templateName: data.templateName,
          language: data.templateLanguage,
          createdByName: loggedUser.name,
          createdById: loggedUser.id,
        };

        const templateSaveResponse = await whatsappService.createTemplate(
          templateDetails
        );

        if (templateSaveResponse.success) {
          setSnackbarMessage("Template has been stored into database");
          setSnackbarOpen(true);
          setTemplates((prevTemplates) => [templateDetails, ...prevTemplates]);
        } else {
          setSnackbarMessage(templateSaveResponse.message);
          setSnackbarOpen(true);
        }
      } else {
        setTemplateResponse(response.data);
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
    } catch (error: any) {
      setSnackbarMessage(`Some error occurred: ${error.message}`);
      setSnackbarOpen(true);
    } finally {
      setSelectedUserIds([]); // Clear selected users
      setLoading(false);
    }
  };

  function handleCloseSnackbar(): void {
    setSnackbarOpen(!snackbarOpen);
  }
  useEffect(() => {
    console.log("this is selecteduserids");
    console.log(selectedUserIds);
  }, [selectedUserIds]);
  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* Top App Bar */}
      <TopAppBar />

      {/* Main Content Section */}
      <Grid container sx={{ flexGrow: 1 }}>
        {/* Left Section */}
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: 2,
            borderRight: "1px solid #ddd",
            height: "90vh",
          }}
        >
          {/* Number Dropdown */}
          <FormControl>
            <InputLabel id="number-select-label">Number</InputLabel>
            <Select
              labelId="number-select-label"
              value={selectedNumber}
              onChange={handleNumberChange}
              variant="outlined"
              size="small"
            >
              <MenuItem value="16479560936">16479560936</MenuItem>
            </Select>
          </FormControl>

          {/* Options */}
          <WhatsappSideBar
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
            settotalUsers={settotalUsers}
            totalUsers={totalUsers}
            fixedUsers={fixedUsers}
            userLoading={userLoading}
            totalNumberOfUsers={totalNumberOfUsers}
            handleScroll={fetchMoreUsers}
            listInnerRef={listInnerRef}
            isLoadingMore={isLoadingMore}
            setUserLoading={setUserLoading}
            fetchUsers={fetchUsers}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            numberofCalls={numberofCalls}
            setNumberOfCalls={setNumberOfCalls}
            setTotalNumberOfUsers={setTotalNumberOfUsers}
            totalCheckItemNumber={totalCheckItemNumber}
            setTotalCheckItemNumber={setTotalCheckItemNumber}
            selectType="number"
            userArray={userArray}
            setUserArray={setUserArray}
            fixedUserNumber={fixedUserNumber}
            setFixedUserNumber={fixedUserNumber}
            hasMoreUsers={hasMoreUsers}
            setHasMoreUsers={setHasMoreUsers}
            firstTimeLoad={firstTimeLoad}
            setFirstTimeLoad={setFirstTimeLoad}
            activeTab={activeTab}
            userWhoseChatIsOpen={userWhoseChatIsOpen}
            setUserWhoseChatIsOpen={setUserWhoseChatIsOpen}
          />
        </Grid>

        {/* Middle Section */}
        <WhatsAppMiddleSection
          activeTab={activeTab}
          conversationId={currentConversationId}
          setConversationId={setCurrentConversationId}
          setActiveTab={setActiveTab}
          handleSend={handleSend}
          loading={loading}
          templates={templates}
          setTemplates={setTemplates}
          templateDetails={templateDetails}
          setTemplateDetails={setTemplateDetails}
          setUserWhoseChatIsOpen={setUserWhoseChatIsOpen}
          setSelectedUserIds={setSelectedUserIds}
        />
        {/* Right Section */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 2,
            height: "90vh",
          }}
        >
          {/* Token Input and Buttons */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              height: "10%",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Token"
              size="small"
              fullWidth
              value={inputValue} // Controlled input
              onChange={(e) => setInputValue(e.target.value)} // Update TextField value
              sx={{
                bgcolor: "white",
                borderRadius: "20px",
                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                },
              }}
            />
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={handleLoadToken} // Handle button click
              sx={{
                borderRadius: "20px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Load
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "20px",
              }}
            >
              Settings
            </Button>
          </Box>

          {/* User Details */}
          {activeTab === 0 ? (
            showChat ? (
              <WhatsAppSingleUserChat
                setShowChat={setShowChat}
                conversationId={currentConversationId}
                token={token}
                userWhoseChatIsOpen={userWhoseChatIsOpen}
                selectedNumber={selectedNumber}
              />
            ) : (
              <Container
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography>Select a User to see the conversation</Typography>
              </Container>
            )
          ) : (
            <ResultsViewer results={templateResponse} loading={loading} />
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Box>
  );
};

export default WhatsApp;
