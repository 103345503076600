import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Grid,
  Paper,
  Box,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import CustomPieChart from "../modules/PieChart";
import TopAppBar from "../modules/TopAppBar";
import { accountService } from "../services";
///// Main functional component for the
const KpiDashboard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [graphData, setGraphData] = useState([]);

  ///// Function for fetching the analytics data ---------------------------------/
  async function fetchAnalyticsData() {
    try {
      setLoading(true);
      const response = await accountService.getAnalytcisData();
      console.log(response);

      if (response.success) {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        setGraphData(response.data);
      } else {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAnalyticsData();
  }, []);
  function handleCloseSnackbar(): void {
    setSnackbarOpen(false);
  }

  return (
    <div>
      {/* 🏆 YOUR CUSTOM TOP BAR GOES HERE */}
      <TopAppBar />

      {/* 🔄 Refresh Button Below Top Bar */}
      {loading ? (
        <Container
          sx={{
            width: "100%",
            display: "flex",
            flexDirectionL: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {loading ? (
              <Container
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Container>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={fetchAnalyticsData}
                startIcon={<RefreshIcon />}
                sx={{ alignSelf: "center" }}
              >
                Refresh
              </Button>
            )}
          </Box>
          {/* 📊 KPI Charts Section (Fully Responsive) */}
          <Grid container spacing={2} sx={{ padding: 1 }}>
            {Object.entries(graphData).map(([title, data]) => (
              <Grid item xs={12} sm={6} md={4} key={title}>
                <CustomPieChart title={title} data={data} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </div>
  );
};

export default KpiDashboard;
