import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tabs,
  Tab,
  Typography,
  Container,
} from "@mui/material";
import {
  TemplatesInterface,
  TotalConversationResponse,
  WhatsappMiddleSectionProps,
} from "../../interface/WhatsAppPageInterface";
import TemplateManager from "./TemplateManager";
import { whatsappService } from "../../services/whatsapp.service";
import { extractMessageContent } from "../../utils/whatsappUtils";
import { of } from "rxjs";

function WhatsAppMiddleSection({
  activeTab,
  conversationId,
  setConversationId,
  setActiveTab,
  handleSend,
  loading,
  templates,
  setTemplates,
  templateDetails,
  setTemplateDetails,
  setUserWhoseChatIsOpen,
  setSelectedUserIds,
}: WhatsappMiddleSectionProps) {
  const [conversations, setConversations] = useState<
    TotalConversationResponse[]
  >([]);
  const [isFetching, setIsFetching] = useState(false);
  const [conversationIds, setConversationIds] = useState<any>([]);
  const [initialLoading, setInitialLoading] = useState(true); // Add state for initial loading

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const handleTabChange = (_event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  async function fetchAllConversation() {
    console.log("fetchall conversation is called");

    if (isFetching) return;
    try {
      setIsFetching(true);
      const [response, templateResponse] = await Promise.all([
        whatsappService.getWhatsAppMessage(conversationIds),
        whatsappService.getWhatsAppTemplates(),
      ]);

      if (response.success) {
        const newConversationIds = response.data.map(
          (chat: any) => chat.conversationId
        );
        setConversationIds((prevIds: any) => [
          ...prevIds,
          ...newConversationIds,
        ]);
        setConversations((prevConversations) =>
          [...prevConversations, ...response.data].sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          )
        );
      }
      if (templateResponse.success) {
        setTemplates(templateResponse.data);
      }
    } catch (error) {
      console.error("Error fetching conversations:", error);
    } finally {
      setIsFetching(false);
      if (initialLoading) {
        setInitialLoading(false); // Set initial loading to false after the first fetch
      }
    }
  }

  useEffect(() => {
    if (activeTab === 0) {
      fetchAllConversation();
    }
  }, [activeTab]);

  // Infinite Scroll: Fetch when reaching bottom
  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current || isFetching) return;

    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      fetchAllConversation();
    }
  }, [isFetching]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const renderTabContent = () => {
    if (activeTab === 0) {
      return (
        <Box
          sx={{ padding: 2, height: "90vh", overflowY: "auto" }}
          ref={chatContainerRef}
        >
          {conversations.length === 0 ? (
            <Paper
              sx={{
                p: 2,
                mb: 2,
                borderRadius: "12px",
                boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar
                src="https://via.placeholder.com/100"
                alt="No Chats Available"
                sx={{ width: 50, height: 50, marginRight: 1 }}
              />
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                No Chats Available
              </Typography>
            </Paper>
          ) : (
            <>
              {conversations.map((chat: any, index) => (
                <Paper
                  key={index}
                  sx={{
                    mb: 2,
                    p: 2,
                    borderRadius: "12px",
                    boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ flex: 1, pr: 2, cursor: "pointer" }}
                    onClick={() =>
                      setUserWhoseChatIsOpen({ _id: chat.user_id })
                    }
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        color: "text.secondary",
                        background: "#f4f4f4",
                        p: 1,
                        borderRadius: "8px",
                      }}
                    >
                      {chat.role === "user"
                        ? extractMessageContent(chat.message, []).body
                        : `You: ${
                            extractMessageContent(chat.message, []).body
                          }`}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontStyle: "italic", color: "text.secondary" }}
                    >
                      {chat.userName}
                    </Typography>
                  </Box>
                  <Avatar
                    src="https://via.placeholder.com/100"
                    alt={chat.userName}
                    sx={{ width: 40, height: 40 }}
                  />
                </Paper>
              ))}

              {isFetching && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <CircularProgress size={30} />
                </Box>
              )}
            </>
          )}
        </Box>
      );
    } else if (activeTab === 1) {
      return (
        <TemplateManager
          handleSend={handleSend}
          loading={loading}
          templates={templates}
          setTemplates={setTemplates}
          templateDetails={templateDetails}
          setTemplateDetails={setTemplateDetails}
          setSelectedUserIds={setSelectedUserIds}
        />
      );
    }
  };

  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "90vh",
      }}
    >
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Chat" />
        <Tab label="Campaign" />
      </Tabs>

      {initialLoading ? ( // Show loader while the initial loading is true
        <Container sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Container>
      ) : (
        renderTabContent()
      )}
    </Grid>
  );
}

export default WhatsAppMiddleSection;
