import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Snackbar,
  CircularProgress,
  Container,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import { useFormik } from "formik";
import * as Yup from "yup";
import { notesService } from "../services/notes.service";
import { accountService } from "../services";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns";
import { da } from "date-fns/locale";

interface NotesModalProps {
  notesModalOpen: boolean;
  handleNotesModalClose: () => void;
  selectedUser: any;
}

const formatDate = (dateString: any) => {
  const date = new Date(dateString);

  return format(date, "MM/dd/yyyy HH:mm"); // Format the date to MM/dd/yyyy HH:mm
};

const NotesModal = ({
  notesModalOpen,
  handleNotesModalClose,
  selectedUser,
}: NotesModalProps) => {
  const [loading, setLoading] = useState(true);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [notes, setNotes] = useState<any>([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const lastNoteRef = useRef<HTMLDivElement | null>(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [noteCheckedStates, setNoteCheckedStates] = useState<boolean[]>([]);
  const [selectedNoteIds, setSelectedNoteIds] = useState<string[]>([]);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [editingNote, setEditingNote] = useState<any>(null);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    setNoteCheckedStates(notes.map(() => checked));
    setSelectedNoteIds(checked ? notes.map((note: any) => note._id) : []);
  };

  const handleNoteCheckboxChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    const updatedNoteCheckedStates = [...noteCheckedStates];
    updatedNoteCheckedStates[index] = checked;
    setNoteCheckedStates(updatedNoteCheckedStates);

    if (checked) {
      setSelectedNoteIds([...selectedNoteIds, notes[index]._id]);
    } else {
      setSelectedNoteIds(
        selectedNoteIds.filter((id) => id !== notes[index]._id)
      );
    }

    const areAllNotesChecked = updatedNoteCheckedStates.every(
      (isChecked) => isChecked
    );
    setSelectAllChecked(areAllNotesChecked);
  };

  const handleDeleteClick = async () => {
    if (selectedNoteIds.length === 0) {
      setSnackbarOpen(true);
      setSnackbarMessage("Please select any note first");
    } else {
      setDeleteLoading(true);
      try {
        const response = await notesService.deleteNotes({
          userId: selectedUser._id,
          notesIdArray: [...selectedNoteIds],
        }); // Implement the deleteNotes function in your notes service

        if (response.success) {
          setNotes(
            notes.filter((note: any) => !selectedNoteIds.includes(note._id))
          );
          setNoteCheckedStates([]);
          setSelectedNoteIds([]);
          setSelectAllChecked(false);
          setSnackbarMessage("Notes deleted successfully");
          setSnackbarOpen(true);
          setDeleteLoading(false);
        } else {
          setNoteCheckedStates([]);
          setSelectedNoteIds([]);
          setSelectAllChecked(false);
          setSnackbarMessage(response.message);
          setSnackbarOpen(true);
          setDeleteLoading(false);
        }
      } catch (error) {
        setSnackbarMessage("Some error has happened");
        setSnackbarOpen(true);
        setDeleteLoading(false);
      }
    }
  };

  const handleEditClick = (note: any) => {
    setEditingNote(note);
    formik.setValues({
      note: note.note,
    });
  };

  function scrollToLastNote() {
    if (lastNoteRef.current) {
      lastNoteRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    scrollToLastNote();
  }, [notes]);

  const formik = useFormik({
    initialValues: {
      note: "",
    },
    validationSchema: Yup.object({
      note: Yup.string().required("Note is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const date = new Date();
      setSubmitButtonLoading(true);
      try {
        const loggedUser = await accountService.userValue;
        if (editingNote) {
          const updatedNote = {
            userId: selectedUser._id,
            noteId: editingNote._id,
            noteDetails: values.note,
            creatorId: loggedUser.id,
            creatorName: loggedUser.name,
            dateTime: date,
          };

          const response = await notesService.editNote(updatedNote);

          // Use the editNote function
          if (response.success) {
            let updatableNote = {
              noteId: editingNote._id,
              note: values.note,
              creatorId: loggedUser.id,
              creatorName: loggedUser.name,
              dateTime: date,
            };

            setNotes(
              notes.map((note: any) =>
                note._id === editingNote._id
                  ? { ...note, ...updatableNote }
                  : note
              )
            );
            setSnackbarMessage(response.message);
            setSnackbarOpen(true);
            setEditingNote(null);
          } else {
            setSnackbarMessage(response.message);
            setSnackbarOpen(true);
          }
        } else {
          let noteData = {
            userId: selectedUser._id,
            notesArray: [
              {
                note: values.note,
                creatorName: loggedUser.name,
                creatorId: loggedUser.id,
                dateTime: date,
              },
            ],
          };
          const response = await notesService.updateNotes(noteData);

          if (response.success) {
            setSnackbarMessage(response.message);
            setSnackbarOpen(true);
            if (notes.length > 0) {
              setNotes([...notes, response.notes]);
            } else {
              let notes = response.notes.notesArray[0];
              setNotes([notes]);
            }

            setNoteCheckedStates([...noteCheckedStates, false]);
          } else {
            setSnackbarMessage(response.message);
            setSnackbarOpen(true);
          }
        }
      } catch (error) {
        console.error("Error submitting note:", error);
      } finally {
        setSubmitButtonLoading(false);
        resetForm();
      }
    },
  });

  async function fetchNotes() {
    setLoading(true);
    try {
      const loggedUser = await accountService.userValue;
      const response = await notesService.fetchNotes(
        selectedUser._id,
        loggedUser.id
      );
      if (response.success) {
        setNotes(response.notes);
        setNoteCheckedStates(response.notes.map(() => false));
      } else {
        setNotes(response.notes);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchNotes();
  }, [selectedUser]);

  function handleCloseSnackbar() {
    setSnackbarOpen(false);
  }

  return (
    <>
      <Modal open={notesModalOpen} onClose={handleNotesModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
          }}
        >
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              width: "40%",
              marginRight: "20px",
            }}
          >
            <Typography variant="h6">
              {editingNote ? "Edit Note" : "Add New Note"}
            </Typography>
            <TextField
              label="Note"
              name="note"
              value={formik.values.note}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              margin="normal"
              error={formik.touched.note && Boolean(formik.errors.note)}
              helperText={formik.touched.note && formik.errors.note}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              fullWidth
              sx={{ marginTop: "16px" }}
              disabled={submitButtonLoading}
            >
              {submitButtonLoading
                ? "Saving..."
                : editingNote
                ? "Update Note"
                : "Add Note"}
            </Button>
          </Box>
          {loading ? (
            <Container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Container>
          ) : (
            <Box
              sx={{
                width: "60%",
                maxHeight: "700px",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Notes</Typography>
                {notes.length > 0 ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                      color="primary"
                      inputProps={{ "aria-label": "select all notes" }}
                    />
                    <IconButton
                      onClick={handleDeleteClick}
                      disabled={deleteLoading}
                    >
                      {deleteLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <DeleteIcon />
                      )}
                    </IconButton>
                  </Box>
                ) : null}
              </Box>
              {notes.map((note: any, index: number) => (
                <Card key={index} sx={{ marginBottom: "10px" }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    ref={index === notes.length - 1 ? lastNoteRef : null}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="body1">{note.note}</Typography>
                      <Typography variant="body1">
                        {formatDate(note.dateTime)}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={noteCheckedStates[index] || false}
                        onChange={(event) =>
                          handleNoteCheckboxChange(index, event)
                        }
                        color="primary"
                        inputProps={{
                          "aria-label": `select note ${index + 1}`,
                        }}
                      />
                      {note.creatorName ? (
                        <Typography variant="body1">
                          Created by : {note.creatorName}
                        </Typography>
                      ) : null}
                      <IconButton onClick={() => handleEditClick(note)}>
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </>
  );
};

export default NotesModal;
