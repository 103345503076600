import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Container,
  Snackbar,
} from "@mui/material";
import { userService } from "../../services/userdetails.service";
import { accountService } from "../../services";

interface AssignFreePremiumModalProps {
  key: any;
  open: boolean;
  onClose: () => void;
  currentUser: any;

  setSelectedUser: any;
  settotalUsers: any;
}

const AssignFreePremiumModal: React.FC<AssignFreePremiumModalProps> = ({
  open,
  onClose,
  currentUser,
  settotalUsers,
  setSelectedUser,
}) => {
  const [startDate, setStartDate] = useState<string | null>(null);

  const [noOfDays, setNoOfDays] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(() => {
    if (currentUser && currentUser.subscription == null) {
      setStartDate(new Date().toISOString().split("T")[0]); // Set current date in ISO format
    } else if (currentUser?.subscription?.receipt) {
      setStartDate(
        currentUser.subscription?.receipt.startDate
          ? new Date(currentUser.subscription?.receipt.startDate)
              .toISOString()
              .split("T")[0] // Format "YYYY-MM-DD"
          : ""
      ); // Ensure it's a string
      setNoOfDays(currentUser.subscription?.receipt.noOfDays?.toString() || ""); // Convert to string if needed
    }
  }, [currentUser]); // Add dependency to re-run when `subscriptionData` changes

  const handleSubmit = async () => {
    if (!startDate || !noOfDays) {
      alert("Please enter both fields.");
      return;
    }

    //// Useeffect  function for stroing user subscription details to the state--/

    const subscriptionData = {
      subscription: {
        receipt: {
          startDate: new Date(startDate).toISOString(),
          noOfDays: parseInt(noOfDays, 10),
        },
        productId: "free_premium",
      },
    };

    try {
      setLoading(true);
      if (currentUser != undefined) {
        const response = await accountService.assign_Free_Premium(
          currentUser._id,
          subscriptionData
        );

        if (response.success == true) {
          setSelectedUser((prevUser: any) => {
            if (!prevUser || prevUser._id !== currentUser._id) return prevUser;

            return {
              ...prevUser,
              subscription: subscriptionData.subscription,
            };
          });

          // Update the subscription for the specific user in the allUsers list
          settotalUsers((prevUsers: any[]) => {
            console.log("Previous All Users:", prevUsers);

            const updatedUsers = prevUsers.map((user) => {
              if (user._id === currentUser._id) {
                console.log("Match Found! Updating User:", user);
                return { ...user, subscription: subscriptionData.subscription };
              } else {
                return user;
              }
            });

            console.log("Updated All Users:", updatedUsers);
            return updatedUsers;
          });
          alert(response.message || "Subscription assigned successfully!");
          // setSnackbarOpen(true);

          onClose(); // Close modal after success
        } else {
          alert(response.message);
          //setSnackbarOpen(true);
          onClose(); // Close modal after success
        }
      } else {
        alert(`Some error has happened`);
        //setSnackbarOpen(false);
      }
    } catch (error) {
      console.error("Error assigning free premium:", error);
      alert("Failed to assign free premium. Please try again.");
      // setSnackbarOpen(false);
    } finally {
      setLoading(false);
    }
  };

  function handleCloseSnackbar(): void {
    setSnackbarOpen(!snackbarOpen);
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Assign Free Premium</DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date" // Change to "date" to exclude time
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="No of Days"
            type="number"
            value={noOfDays}
            onChange={(e) => setNoOfDays(e.target.value)}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
          {loading ? (
            <Container>
              <CircularProgress />
            </Container>
          ) : (
            <Button onClick={handleSubmit} color="primary" variant="contained">
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </>
  );
};

export default AssignFreePremiumModal;
