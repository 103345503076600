import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { additionalService } from "../../services/additional.service";

interface MultipleChoiceFormProps {
  onClose: () => void;
  onSave: (data: any) => void;
  initialData?: {
    uuid: string;
    input_fields: {
      title: string;
      body?: string | null;
      imageUrl?: string | null;
      redirectionPage?: string | null;
      selectionType: "single" | "multiple";
      otherEnabled?: boolean | null;
      options: { label: string; imageUrl?: string | null }[];
    };
  };

  campaignId: string | null;
  type: string | null;
  sequence: any;

  editingInteractionId: any;
  onEdit: (data: any) => void;
}
interface OptionType {
  label: string;
  image: File | null;
  imageUrl: string | null;
}

interface FormDataType {
  title: string;
  redirectionPage: string | null;
  body: string;
  selectionType: string;
  otherEnabled: boolean;
  options: OptionType[];

  mainImageFile: File | null;
  mainImageUrl: string | null;
  uuid?: string; // Optional field for UUID
}

const MultipleChoiceForm: React.FC<MultipleChoiceFormProps> = ({
  onClose,
  onSave,
  initialData,
  campaignId,
  type,
  sequence,
  editingInteractionId,
  onEdit,
}) => {
  console.log(initialData);

  // Consolidated formData state
  const [formData, setFormData] = useState<FormDataType>(() => {
    const baseFormData: FormDataType = {
      title: initialData?.input_fields?.title || "",
      body: initialData?.input_fields?.body || "",
      redirectionPage: initialData?.input_fields?.redirectionPage || null,
      selectionType: initialData?.input_fields?.selectionType || "single",
      otherEnabled: initialData?.input_fields?.otherEnabled || false,
      options: initialData?.input_fields?.options.map((opt) => ({
        label: opt.label,
        image: null,
        imageUrl: opt.imageUrl || null,
      })) || [{ label: "", image: null, imageUrl: null }],
      mainImageFile: null,
      mainImageUrl: initialData?.input_fields?.imageUrl || null,
    };

    return initialData?.uuid
      ? { ...baseFormData, uuid: initialData.uuid }
      : baseFormData;
  });

  const [loading, setLoading] = useState(false);
  // Handle checkbox change
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => {
      return {
        ...prev,
        otherEnabled: event.target.checked,
      };
    });
  };
  // Handle input field changes
  const handleInputChange = (
    field: string,
    value: string | "single" | "multiple"
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  // Handle changes to options
  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...formData.options];
    newOptions[index].label = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      options: newOptions,
    }));
  };

  // Handle image file changes for the main image
  const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        mainImageFile: file,
        mainImageUrl: null, // Reset the image URL since a new file is selected
      }));
    }
  };

  // Remove the main image
  const handleRemoveMainImage = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      mainImageFile: null,
      mainImageUrl: null,
    }));
  };

  // Handle image file changes for options
  const handleImageChangeForOption = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const newOptions = [...formData.options];
      newOptions[index].image = file;
      setFormData((prevFormData) => ({
        ...prevFormData,
        options: newOptions,
      }));
    }
  };

  // Remove the image for options
  const handleRemoveImageForOption = (index: number) => {
    const newOptions = [...formData.options];
    newOptions[index].image = null;
    newOptions[index].imageUrl = null; // Clear image URL if removing image
    setFormData((prevFormData) => ({
      ...prevFormData,
      options: newOptions,
    }));
  };

  // Handle add new option
  const handleAddOption = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      options: [
        ...prevFormData.options,
        { label: "", image: null, imageUrl: null },
      ],
    }));
  };

  const handleSubmit = async () => {
    if (!formData.title || formData.options.length === 0) {
      alert("Please fill out all fields.");
      return;
    }

    setLoading(true);
    try {
      // Upload the main image if available
      let mainImageUrl = formData.mainImageUrl;
      if (formData.mainImageFile) {
        mainImageUrl = await additionalService.uploadImageOnAzure(
          formData.mainImageFile
        );
      }

      // Upload option images concurrently
      const uploadedOptions = await Promise.all(
        formData.options.map(async (option, index) => {
          if (option.image) {
            const imageUrl = await additionalService.uploadImageOnAzure(
              option.image
            );
            return { label: option.label, imageUrl };
          } else {
            return { label: option.label, imageUrl: option.imageUrl };
          }
        })
      );

      const saveData = {
        campaign_id: campaignId, // Keep existing campaign_id
        sequence: sequence, // Keep existing sequence
        type: type, // Keep existing type
        input_fields: {
          title: formData.title,
          body: formData.body,
          redirectionPage: formData.redirectionPage,
          otherEnabled: formData.otherEnabled,
          selectionType: formData.selectionType,
          options: uploadedOptions,
          imageUrl: mainImageUrl,
        },
      };

      onSave(saveData); // Pass the structured data
      onClose(); // Close the form after saving
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    if (!formData.title || formData.options.length === 0) {
      alert("Please fill out all fields.");
      return;
    }

    setLoading(true);
    try {
      // Upload the main image if available
      let mainImageUrl = formData.mainImageUrl;
      if (formData.mainImageFile) {
        mainImageUrl = await additionalService.uploadImageOnAzure(
          formData.mainImageFile
        );
      }

      // Upload option images concurrently
      const uploadedOptions = await Promise.all(
        formData.options.map(async (option, index) => {
          if (option.image) {
            const imageUrl = await additionalService.uploadImageOnAzure(
              option.image
            );
            return { label: option.label, imageUrl };
          } else {
            return { label: option.label, imageUrl: option.imageUrl };
          }
        })
      );
      let saveData;
      if ("uuid" in formData) {
        saveData = {
          index: editingInteractionId,
          campaign_id: campaignId,
          sequence: sequence,
          type: type,
          uuid: formData.uuid,
          input_fields: {
            title: formData.title,
            redirectionPage: formData.redirectionPage,
            otherEnabled: formData.otherEnabled,
            body: formData.body,
            selectionType: formData.selectionType,
            options: uploadedOptions,
            imageUrl: mainImageUrl,
          },
        };
      }
      onEdit(saveData); // Pass the structured data
      onClose(); // Close the form after saving
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        width: 400,
        maxHeight: "60vh",
        overflowY: "auto",
        mx: "auto",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "background.paper",
        borderRadius: 2,
        boxShadow: 3,
        padding: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Multiple Choice Form
      </Typography>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.title}
        onChange={(e) => handleInputChange("title", e.target.value)}
      />
      <TextField
        label="Body"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.body}
        onChange={(e) => handleInputChange("body", e.target.value)}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Selection Type</InputLabel>
        <Select
          value={formData.selectionType}
          onChange={(e) =>
            handleInputChange(
              "selectionType",
              e.target.value as "single" | "multiple"
            )
          }
          label="Selection Type"
        >
          <MenuItem value="multiple">Multiple</MenuItem>
          <MenuItem value="single">Single</MenuItem>
        </Select>
      </FormControl>
      {/* Conditionally render the checkbox below "Multiple" */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Redirection Page</InputLabel>
        <Select
          value={formData.redirectionPage || ""}
          onChange={(e) => handleInputChange("redirectionPage", e.target.value)}
          variant="outlined"
          label="Redirection Page"
          displayEmpty
        >
          <MenuItem value="diet plan">Diet Plan</MenuItem>
          <MenuItem value="history">History</MenuItem>
          <MenuItem value="workouts">Workouts</MenuItem>
          <MenuItem value="nextinteraction">Next Interactions</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.otherEnabled}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label="Others"
      />

      <Button
        variant="outlined"
        component="label"
        fullWidth
        color="primary"
        sx={{ mb: 2 }}
      >
        Upload Main Image (Optional)
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleMainImageChange}
        />
      </Button>
      {(formData.mainImageFile || formData.mainImageUrl) && (
        <Box
          sx={{ mt: 2, position: "relative", textAlign: "left", width: "100%" }}
        >
          <Typography variant="body2">
            {formData.mainImageFile
              ? formData.mainImageFile.name
              : formData.mainImageUrl?.split("/").pop()}
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleRemoveMainImage}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      {formData.options.map((option, index) => (
        <Box key={index} sx={{ mb: 2, width: "100%" }}>
          <TextField
            label={`Option ${index + 1}`}
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            value={option.label}
            onChange={(e) => handleOptionChange(index, e.target.value)}
          />
          <Button
            variant="outlined"
            component="label"
            fullWidth
            color="primary"
          >
            Upload Image for Option {index + 1} (Optional)
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={(e) => handleImageChangeForOption(index, e)}
            />
          </Button>
          {(option.image || option.imageUrl) && (
            <Box sx={{ mt: 2, position: "relative", textAlign: "left" }}>
              <Typography variant="body2">
                {option.image
                  ? option.image.name
                  : option.imageUrl?.split("/").pop()}
              </Typography>
              <IconButton
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => handleRemoveImageForOption(index)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}

      <Button variant="outlined" onClick={handleAddOption}>
        Add Option
      </Button>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {initialData ? (
            <Button variant="contained" color="primary" onClick={handleEdit}>
              Update
              {/* Change button text based on mode */}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Add
              {/* Change button text based on mode */}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default MultipleChoiceForm;
