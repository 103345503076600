/** @format */

import { boolean } from "yup";
import { FilterInterface, FilterSettings } from "../interface/FilterInterface";

//// Initial state for the filters----------------------------------------/
export const FilterInitialState = {
  activity: {
    date: null,
    activityType: "",
  },
  uncheckedUserMessagesFilter: undefined,
  weighingScaleFilter: undefined,
  freePremiumOverFilter: undefined,
  onboardingFilter: undefined,
  heardSocialMediaFilter: undefined,
  heardFriendFilter: undefined,
  heardCoWorkerFilter: undefined,
  heardFamilyFilter: undefined,
  heardStoreSearchFilter: undefined,
  heardOtherFilter: undefined,
  hasGymMembershipFilter: undefined,
  subscriptionFilter: undefined,
  notesFilter: undefined,
  notificationFilter: undefined,
  contactNumberFilter: undefined,
  deviceType: null,
  maxVersion: undefined,
  minVersion: undefined,
  genderType: null,
  goalType: [],
  activeType: null,
  freePremiumFilter: undefined,
  numberOfCalls: 1,
  dateRange: {
    startDate: null,
    endDate: null,
  },
  uncheckCountries: [],
  checkBoxCountries: [],
  messageSeenFilter: undefined,
  countryCode: undefined,
  csvFileFilter: undefined,
  distinctIds: [],
  openSupportMessage: undefined,
  minimumAge: undefined,
  maximumAge: undefined,
  maximumBmi: undefined,
  minimumBmi: undefined,
  freeTrialFilter: undefined,
  paidFilter: undefined,
  signUpFilter: null,
  subscriptionCanceledFilter: undefined,
  initialQuotaFilter: undefined,
};

/// Intitial state for the filter settings -------------------------------------------------/
export const initialFilterSettings: FilterSettings = {
  subscriptionCheckBox: false,
  uncheckedUserMessagesCheckBox: false,
  onboardingCheckBox: false,
  notificationCheckBox: false,
  weighingScaleCheckBox: false,
  notesCheckBox: false,
  heardSocialMediaCheckBox: false,
  heardFriendCheckBox: false,
  heardCoWorkerCheckBox: false,
  heardFamilyCheckBox: false,
  heardStoreSearchCheckBox: false,
  heardOtherCheckBox: false,
  freePremiumOverCheckBox: false,
  hasGymMembershipCheckBox: false,
  contactNumberCheckBox: false,
  isDatePickerDisabled: false,
  isToggleButtonGroupDisabled: false,
  isDateRangeDisabled: false,
  messageSeenCheckBox: false,
  csvFileCheckBox: false,
  openSupportMessageCheckBox: false,
  freePremiumCheckBox: false,
  freeTrialCheckBox: false,
  paidCheckBox: false,
  subscriptionCanceledCheckBox: false,
  initialQuotaCheckBox: false,
};

/// Types for checkbox filters --------------------------------------------------------------/
type FilterName =
  | "subscription"
  | "onboarding"
  | "notes"
  | "notification"
  | "contactNumber"
  | "messageSeen"
  | "freePremiumOver"
  | "hasGymMembership"
  | "openSupportMessage"
  | "uncheckedUserMessages"
  | "freePremium"
  | "heardSocialMedia"
  | "heardFriend"
  | "heardCoWorker"
  | "heardFamily"
  | "heardStoreSearch"
  | "heardOther"
  | "freeTrial"
  | "paid"
  | "weighingScale"
  | "csvFile"
  | "subscriptionCanceled"
  | "initialQuota";
/// Filter object for checkbox for the filtername and checkbox name--------------------------/

export const checkBoxFilterObject: Record<
  FilterName,
  { checkBox: keyof FilterSettings; filter: keyof FilterInterface }
> = {
  subscription: {
    checkBox: "subscriptionCheckBox",
    filter: "subscriptionFilter",
  },
  uncheckedUserMessages: {
    checkBox: "uncheckedUserMessagesCheckBox",
    filter: "uncheckedUserMessagesFilter",
  },
  weighingScale: {
    checkBox: "weighingScaleCheckBox",
    filter: "weighingScaleFilter",
  },
  freePremiumOver: {
    checkBox: "freePremiumOverCheckBox",
    filter: "freePremiumOverFilter",
  },
  hasGymMembership: {
    checkBox: "hasGymMembershipCheckBox",
    filter: "hasGymMembershipFilter",
  },
  freeTrial: {
    checkBox: "freeTrialCheckBox",
    filter: "freeTrialFilter",
  },
  initialQuota: {
    checkBox: "initialQuotaCheckBox",
    filter: "initialQuotaFilter",
  },

  subscriptionCanceled: {
    checkBox: "subscriptionCanceledCheckBox",
    filter: "subscriptionCanceledFilter",
  },

  paid: {
    checkBox: "paidCheckBox",
    filter: "paidFilter",
  },
  onboarding: {
    checkBox: "onboardingCheckBox",
    filter: "onboardingFilter",
  },
  heardFamily: {
    checkBox: "heardFamilyCheckBox",
    filter: "heardFamilyFilter",
  },
  heardSocialMedia: {
    checkBox: "heardSocialMediaCheckBox",
    filter: "heardSocialMediaFilter",
  },
  heardCoWorker: {
    checkBox: "heardCoWorkerCheckBox",
    filter: "heardCoWorkerFilter",
  },
  heardFriend: {
    checkBox: "heardFriendCheckBox",
    filter: "heardFriendFilter",
  },
  heardStoreSearch: {
    checkBox: "heardStoreSearchCheckBox",
    filter: "heardStoreSearchFilter",
  },
  heardOther: {
    checkBox: "heardOtherCheckBox",
    filter: "heardOtherFilter",
  },
  notes: {
    checkBox: "notesCheckBox",
    filter: "notesFilter",
  },
  notification: {
    checkBox: "notificationCheckBox",
    filter: "notificationFilter",
  },
  contactNumber: {
    checkBox: "contactNumberCheckBox",
    filter: "contactNumberFilter",
  },
  messageSeen: {
    checkBox: "messageSeenCheckBox",
    filter: "messageSeenFilter",
  },
  freePremium: {
    checkBox: "freePremiumCheckBox",
    filter: "freePremiumFilter",
  },
  csvFile: {
    checkBox: "csvFileCheckBox",
    filter: "csvFileFilter",
  },
  openSupportMessage: {
    checkBox: "openSupportMessageCheckBox",
    filter: "openSupportMessage",
  },
};

export const isFilterName = (name: string): name is FilterName => {
  return [
    "subscription",
    "onboarding",
    "notes",
    "notification",
    "contactNumber",
    "messageSeen",
    "uncheckedUserMessages",
    "hasGymMembership",
    "csvFile",
    "freePremiumOver",
    "openSupportMessage",
    "freePremium",
    "paid",
    "freeTrial",
    "weighingScale",
    "heardFamily",
    "heardSocialMedia",
    "heardCoWorker",
    "heardFriend",
    "heardStoreSearch",
    "heardOther",
    "subscriptionCanceled",
    "initialQuota",
  ].includes(name);
};
