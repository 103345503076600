/** @format */

import { useEffect, useState } from "react";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Checkbox,
  Button,
  Container,
  Modal,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { NotificationSideBarPropsInterface } from "../interface/NotificationInterface";
import SortingModal from "./SortingModal";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { userService } from "../services/userdetails.service";
import { SelectedUserInterface } from "../interface/User";
import {
  FilterInterface,
  FilterSettings,
  FiltersInterface,
} from "../interface/FilterInterface";
import { notificationUtils } from "../utils/Notification";
import { DateRange } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroller";
import { FilterInitialState, initialFilterSettings } from "../utils/FilterUtis";
import SubscriptionShowingModal from "./SubscriptionShowingModal";
//// Main NotificationSideBar funciton-------------------------------------------/

const NotificationSideBar = ({
  selectedUserIds,
  setSelectedUserIds,
  fixedUsers,
  totalUsers,
  settotalUsers,
  handleScroll,
  isLoadingMore,
  listInnerRef,
  userLoading,
  setUserLoading,
  totalNumberOfUsers,
  numberofCalls,
  fetchUsers,
  selectAll,
  setNumberOfCalls,
  setTotalNumberOfUsers,
  setSelectAll,
  totalCheckItemNumber,
  setTotalCheckItemNumber,
  selectType,

  fixedUserNumber,
  setFixedUserNumber,
  hasMoreUsers,
  setHasMoreUsers,
  firstTimeLoad,
  setFirstTimeLoad,
  activeTab,
  setSelectedUser,
  selectedUser,
}: NotificationSideBarPropsInterface) => {
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState<string | null>(null);
  ///State for the filters -------------------------------------------------------------------------/
  const [filters, setFilters] = useState<FilterInterface>(FilterInitialState);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchBarActive, setSearchBarActive] = useState(false);
  /// State for the filters settings ( like checkbox turn on off )---------------------------------/
  const [filterSettings, setFilterSettings] = useState<FilterSettings>(
    initialFilterSettings
  );
  const [subscribedUserDetails, setSubscribedUserDetails] =
    useState<SelectedUserInterface | null>(null);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  function onClose() {
    setSubscriptionModalOpen(!subscriptionModalOpen);
  }
  useEffect(() => {
    setFirstTimeLoad(true);
    if (!fixedUsers) return;
    const timerId = setTimeout(async () => {
      if (!searchText.trim()) {
        settotalUsers(fixedUsers);
        setTotalNumberOfUsers(fixedUserNumber);
        setHasMoreUsers(true);
        return;
      }

      setUserLoading(true);

      try {
        let fetchUsersPromise;

        // if (loggedUser.role === "admin") {
        fetchUsersPromise = userService.getSearchUsersOfAdmin(
          searchText,
          filters
        );
        // } else {
        //   fetchUsersPromise = userService.getSearchUsersOfTrainer(
        //     loggedUser.trainerCode,
        //     searchText
        //   );
        // }

        const fetchedUsers = await fetchUsersPromise;
        setHasMoreUsers(false);
        setTotalNumberOfUsers(fetchedUsers.length);
        settotalUsers(fetchedUsers);
      } catch (err) {
        setError("Failed to fetch users.");
      } finally {
        setUserLoading(false);
      }
    }, 1000); // Debounce time is 500ms

    return () => clearTimeout(timerId);
  }, [searchText, fixedUsers]);

  const handleSearchChange = (event: any) => {
    if (event.target.value !== "") {
      //// searchbar is not empty so the user appears with the searchbar searching
      /// will be added with the filter ones so we will make searchbaractive state
      /// true just to track that
      setSearchBarActive(true);
      setSearchText(event.target.value);
      setError(null); // Reset error when user types
    } else {
      setSearchBarActive(false);
      //// searbacj is empty so no user will be added based on searchbar
      setSearchText(event.target.value);
      setError(null); // Reset error when user types
    }
  };
  const handleCheckboxChange = (user: any, fcmToken: any) => {
    if (selectType === "id") {
      let userId = user._id;
      let email = user.email;
      let name = user.name; // Capture user name

      setSelectedUserIds((prevSelected: any) => {
        const existingUserIndex = prevSelected.findIndex(
          (item: any) => item.userId === userId
        );
        let newSelected: any[] = []; // Explicitly define the type

        if (existingUserIndex > -1) {
          // User is being unselected
          newSelected = [
            ...prevSelected.slice(0, existingUserIndex),
            ...prevSelected.slice(existingUserIndex + 1),
          ];
          setSelectAll(false); // Ensure selectAll is false when unselecting
        } else {
          // User is being selected
          newSelected = [...prevSelected, { userId, fcmToken, email, name }];
        }

        updateAllUserForNotification(newSelected);

        if (
          newSelected.length === fixedUserNumber &&
          existingUserIndex === -1
        ) {
          // Only set selectAll to true when all users are selected
          setTotalCheckItemNumber(fixedUserNumber);
          setSelectAll(true);
        } else {
          setTotalCheckItemNumber(newSelected.length);
        }

        return newSelected;
      });
    } else if (selectType === "email") {
      let email = user.email;
      let name = user.name; // Capture user name

      setSelectedUserIds((prevSelected: any) => {
        const existingUserIndex = prevSelected.findIndex(
          (item: any) => item.email === email
        );
        let newSelected: any[] = []; // Explicitly define the type

        if (existingUserIndex > -1) {
          // User is being unselected
          newSelected = [
            ...prevSelected.slice(0, existingUserIndex),
            ...prevSelected.slice(existingUserIndex + 1),
          ];
          setSelectAll(false); // Ensure selectAll is false when unselecting
        } else {
          // User is being selected
          newSelected = [...prevSelected, { email, fcmToken, name }];
        }

        updateAllUserForNotification(newSelected);

        if (
          newSelected.length === fixedUserNumber &&
          existingUserIndex === -1
        ) {
          // Only set selectAll to true when all users are selected
          setTotalCheckItemNumber(fixedUserNumber);
          setSelectAll(true);
        } else {
          setTotalCheckItemNumber(newSelected.length);
        }

        return newSelected;
      });
    } else if (selectType === "number") {
      let contactNumber = user.contactNumber; // Use contactNumber as identifier
      let userId = user._id; // Store user._id as userId
      let name = user.name; // Capture user name

      setSelectedUserIds((prevSelected: any) => {
        const existingUserIndex = prevSelected.findIndex(
          (item: any) => item.userId === userId // Use userId for selection check
        );
        let newSelected: any[] = []; // Explicitly define the type

        if (existingUserIndex > -1) {
          // User is being unselected
          newSelected = [
            ...prevSelected.slice(0, existingUserIndex),
            ...prevSelected.slice(existingUserIndex + 1),
          ];
          setSelectAll(false); // Ensure selectAll is false when unselecting
        } else {
          // User is being selected
          newSelected = [
            ...prevSelected,
            { userId, contactNumber, fcmToken, name },
          ];
        }

        updateAllUserForNotification(newSelected);

        if (
          newSelected.length === fixedUserNumber &&
          existingUserIndex === -1
        ) {
          // Only set selectAll to true when all users are selected
          setTotalCheckItemNumber(fixedUserNumber);
          setSelectAll(true);
        } else {
          setTotalCheckItemNumber(newSelected.length);
        }

        return newSelected;
      });
    }
  };

  const updateAllUserForNotification = (selectedUsers: any[]) => {
    const updatedUsers = selectedUsers.map((user) => ({
      fcmToken: user.fcmToken,
      email: user.email,
      id: user.userId,
      contactNumber: user.contactNumber,
      name: user.name,
    }));

    setSelectedUserIds(updatedUsers);
  };

  // Other state variables and functions...
  const handleSelectAllChange = async () => {
    if (selectType === "id") {
      setSelectAll(!selectAll);
      if (!selectAll) {
        const allUserIds = totalUsers.map((user) => ({
          userId: user._id,
          fcmToken: user.fcmToken,
        }));

        //// Getting all the users who are matching the filteredObject---------------------
        if (searchText) {
          setSelectedUserIds((prevSelected: any) => {
            const newUsers = allUserIds.filter(
              (user) =>
                !prevSelected.some(
                  (selected: any) => selected.userId === user.userId
                )
            );
            setTotalCheckItemNumber(
              (prevTotal: any) => prevTotal + newUsers.length
            );
            return [...prevSelected, ...newUsers];
          });
        } else {
          let getAllSendableUsers: any;

          getAllSendableUsers = await notificationUtils.getTotalUsers(
            numberofCalls,
            filters
          );

          if (getAllSendableUsers) {
            setSelectedUserIds((prevSelected: any) => {
              const newUsers = getAllSendableUsers.filter(
                (user: any) =>
                  !prevSelected.some(
                    (selected: any) => selected.userId === user.userId
                  )
              );
              setTotalCheckItemNumber(
                (prevTotal: any) => prevTotal + newUsers.length
              );
              return [...prevSelected, ...newUsers];
            });
          }
        }
      } else {
        setSelectedUserIds([]);
        setTotalCheckItemNumber(0);
      }
    } else if (selectType === "email") {
      setSelectAll(!selectAll);
      if (!selectAll) {
        const allUserEmails = totalUsers.map((user) => ({
          email: user.email,
          fcmToken: user.fcmToken,
        }));

        //// Getting all the users who are matching the filteredObject---------------------
        if (searchText) {
          setSelectedUserIds((prevSelected: any) => {
            const newUsers = allUserEmails.filter(
              (user) =>
                !prevSelected.some(
                  (selected: any) => selected.email === user.email
                )
            );
            setTotalCheckItemNumber(
              (prevTotal: any) => prevTotal + newUsers.length
            );
            return [...prevSelected, ...newUsers];
          });
        } else {
          const getAllSendableUsers = await notificationUtils.getTotalUsers(
            numberofCalls,
            filters
          );

          if (getAllSendableUsers) {
            setSelectedUserIds((prevSelected: any) => {
              const newUsers = getAllSendableUsers.filter(
                (user: any) =>
                  !prevSelected.some(
                    (selected: any) => selected.email === user.email
                  )
              );
              setTotalCheckItemNumber(
                (prevTotal: any) => prevTotal + newUsers.length
              );
              return [...prevSelected, ...newUsers];
            });
          }
        }
      } else {
        setSelectedUserIds([]);
        setTotalCheckItemNumber(0);
      }
    } else if (selectType === "number") {
      setSelectAll(!selectAll);
      if (!selectAll) {
        const allUserEmails = totalUsers.map((user: any) => ({
          email: user.email,
          fcmToken: user.fcmToken,
          contactNumber: user.contactNumber,
        }));

        //// Getting all the users who are matching the filteredObject---------------------
        if (searchText) {
          setSelectedUserIds((prevSelected: any) => {
            const newUsers = allUserEmails.filter(
              (user) =>
                !prevSelected.some(
                  (selected: any) => selected.email === user.email
                )
            );
            setTotalCheckItemNumber(
              (prevTotal: any) => prevTotal + newUsers.length
            );
            return [...prevSelected, ...newUsers];
          });
        } else {
          const getAllSendableUsers = await notificationUtils.getTotalUsers(
            numberofCalls,
            filters
          );

          if (getAllSendableUsers) {
            setSelectedUserIds((prevSelected: any) => {
              const newUsers = getAllSendableUsers.filter(
                (user: any) =>
                  !prevSelected.some(
                    (selected: any) => selected.email === user.email
                  )
              );
              setTotalCheckItemNumber(
                (prevTotal: any) => prevTotal + newUsers.length
              );
              return [...prevSelected, ...newUsers];
            });
          }
        }
      } else {
        setSelectedUserIds([]);
        setTotalCheckItemNumber(0);
      }
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  function resetCheckBoxFunctionaltiy() {
    //setAllUserForNotification([]);

    setTotalCheckItemNumber(selectedUserIds.length);
    //setSelectedUserIds([]);
    setSelectAll(false);
  }

  const loadMoreHandler = async (page: number) => {
    if (firstTimeLoad) {
      if (!isLoadingMore) {
        // Call your fetchMoreUsers function here
        handleScroll(numberofCalls, filters, hasMoreUsers);
      }
    } else {
      //await fetchUsers();
    }
  };
  function handleUnselectAll(): void {
    setSelectedUserIds([]);
    setTotalCheckItemNumber(0);
    setSelectAll(false);
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            type="search"
            variant="outlined"
            fullWidth
            onChange={handleSearchChange}
            value={searchText}
            placeholder="Search users"
            autoComplete="off"
            style={{ width: "60%" }} // Set the width to 100%
            disabled={userLoading}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ width: "30", marginLeft: 10 }}
            onClick={handleOpenModal}
            disabled={userLoading}
          >
            Sort
          </Button>
          {userLoading ? (
            <div>
              <CircularProgress style={{ fontSize: 10, marginLeft: 5 }} />
            </div>
          ) : (
            <Typography style={{ color: "blue", marginLeft: 5 }}>
              {`${totalNumberOfUsers}(${totalCheckItemNumber})`}
            </Typography>
          )}
        </div>
        {totalNumberOfUsers > 0 ? (
          <>
            {activeTab === 1 ? (
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "auto", // Set width to auto
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    inputProps={{ "aria-label": "select all users" }}
                  />
                  <Typography variant="subtitle1" style={{ marginTop: 5 }}>
                    Select All
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "auto", // Set width to auto
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Unselect button with small undo icon */}
                  <Button
                    variant="outlined" // Adds border to the button
                    onClick={handleUnselectAll} // Add the function to handle unselect all
                    startIcon={<UndoIcon />} // Add the undo icon to the left of the text
                    style={{
                      color:
                        selectedUserIds.length === 0
                          ? "rgba(0, 0, 0, 0.5)"
                          : "black", // Make text color faded if disabled
                      borderColor:
                        selectedUserIds.length === 0
                          ? "rgba(0, 0, 0, 0.5)"
                          : "black", // Make border color faded if disabled
                      marginLeft: "8px", // Space between checkbox and button
                      fontSize: "12px", // Smaller text size
                      textTransform: "none", // Keep text as "Unselect All", no uppercase
                      opacity: selectedUserIds.length === 0 ? 0.5 : 1, // Faded look if disabled
                      cursor:
                        selectedUserIds.length === 0
                          ? "not-allowed"
                          : "pointer", // Show "not allowed" cursor when disabled
                    }}
                    disabled={selectedUserIds.length === 0} // Disable button if no users are selected
                  >
                    Unselect All
                  </Button>
                </div>
              </Container>
            ) : null}
          </>
        ) : null}
      </div>

      {modalOpen ? (
        <SortingModal
          filterSettings={filterSettings}
          setFilterSettings={setFilterSettings}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          totalUsers={totalUsers}
          setTotalUsers={settotalUsers}
          fixedUsers={fixedUsers}
          listInnerRef={listInnerRef}
          setNumberOfCalls={setNumberOfCalls}
          setTotalNumberOfUsers={setTotalNumberOfUsers}
          setUserLoading={setUserLoading}
          resetCheckBoxFunctionaltiy={resetCheckBoxFunctionaltiy}
          setHasMoreUsers={setHasMoreUsers}
          filters={filters}
          setFilters={setFilters}
          fetchUsers={fetchUsers}
          selectedUserIds={selectedUserIds}
          searchBarActive={searchBarActive}
          searchText={searchText}
        />
      ) : null}

      <Grid item xs={12}>
        {userLoading ? (
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ marginTop: 35 }} />
          </Container>
        ) : (
          <div
            style={{
              height: "70vh",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMoreHandler}
              hasMore={hasMoreUsers}
              loader={
                <Container
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  key="loader" // Ensure the loader has a key
                >
                  <CircularProgress key="loader" />
                </Container>
              }
              useWindow={false} // Adjust if you're using a specific scrollable container
            >
              <List id="scrollableDiv">
                {totalUsers.map((user: any, index: any) => (
                  <ListItem
                    key={user._id}
                    sx={{
                      cursor: "pointer",
                      bgcolor:
                        user._id === selectedUser?._id ? "grey.300" : "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedUser(user);
                      }}
                    >
                      <ListItemText
                        primary={user.name || "No Name"}
                        secondary={user.email}
                      />
                    </Box>
                    <div style={{ width: "10px", flexShrink: 0 }}>
                      {user.subscription ? (
                        <GppGoodIcon
                          style={{
                            color: "#0D47A1",
                            cursor: "pointer",
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            setSubscribedUserDetails(user);
                            setSubscriptionModalOpen(!subscriptionModalOpen);
                          }}
                        />
                      ) : null}
                    </div>
                    <div style={{ flexShrink: 0 }}>
                      {!user.subscription && user.freePremiumOver ? (
                        <GppGoodIcon
                          style={{
                            color: "#9E9E9E",
                            cursor: "pointer",
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            setSubscribedUserDetails(user);
                            setSubscriptionModalOpen(!subscriptionModalOpen);
                          }}
                        />
                      ) : null}
                    </div>
                    {activeTab === 1 ? (
                      <Checkbox
                        checked={
                          selectType === "id" || selectType === "number"
                            ? selectedUserIds.some(
                                (item: any) => item.userId === user._id
                              )
                            : selectType === "email"
                            ? selectedUserIds.some(
                                (item: any) => item.email === user.email
                              )
                            : false
                        }
                        sx={{ marginLeft: 1 }}
                        onChange={() =>
                          handleCheckboxChange(user, user.fcmToken)
                        }
                      />
                    ) : null}
                  </ListItem>
                ))}
                {error && (
                  <ListItem>
                    <ListItemText
                      primary={error}
                      sx={{ color: "error.main" }}
                    />
                  </ListItem>
                )}
              </List>
            </InfiniteScroll>
          </div>
        )}
      </Grid>
      {subscriptionModalOpen && (
        <SubscriptionShowingModal
          subscriptionModalOpen={subscriptionModalOpen}
          onClose={onClose}
          selectedUser={subscribedUserDetails}
        />
      )}
    </>
  );
};

export default NotificationSideBar;
